// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aS3qY{display:flex;align-items:center;justify-content:center;gap:var(--gap-small)}@keyframes rLVpw{from{left:-200%}to{left:200%}}.aS3qY{padding:var(--padding-tag);border-radius:var(--border-radius)}.aS3qY.eS0CT{cursor:pointer}.aS3qY.bAcWr{font-size:1em;font-weight:500;background-color:var(--color-light-alt)}.aS3qY.WNV8U{font-size:1em;font-weight:300;background-color:var(--color-white);color:var(--color-primary);box-shadow:var(--box-shadow-min)}.aS3qY.G5RDw{font-size:1em;font-weight:600;background-color:var(--color-primary-light-alt);color:var(--color-black)}.aS3qY.gNIaI{padding:6px 15px;font-size:.85em;font-weight:500;background-color:var(--color-primary-light-alt);color:var(--color-black)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Tag/Tag.module.scss"],"names":[],"mappings":"AAgBA,OACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oBAAA,CCSA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OAGC,0BAAA,CACA,kCAAA,CAEA,aACC,cAAA,CAMD,aACC,aAAA,CACA,eAAA,CACA,uCAAA,CAGD,aACC,aAAA,CACA,eAAA,CACA,mCAAA,CACA,0BAAA,CACA,gCAAA,CAGD,aACC,aAAA,CACA,eAAA,CACA,+CAAA,CACA,wBAAA,CAGD,aACC,gBAAA,CACA,eAAA,CACA,eAAA,CACA,+CAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `aS3qY`,
	"click": `eS0CT`,
	"type-default": `bAcWr`,
	"type-2": `WNV8U`,
	"type-3": `G5RDw`,
	"type-4": `gNIaI`,
	"animationPlaceholderLoading": `rLVpw`
};
export default ___CSS_LOADER_EXPORT___;
