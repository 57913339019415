import React, { useContext } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './RecipeContainer.module.scss';

// Images
import imgIconAdapt from '@icon/icon-adapt.svg';
import imgIconShop from '@icon/icon-shop.svg';
import imgIconCook from '@img/default/logo-single.svg';
import imgIconX from '@icon/x-alt.svg';

// Cpnt
import { BtnAlt } from '@cpnt/Btns/BtnIcon/BtnAlt';
import { joinClass } from '@func/cpnt';
import { RecipeHead } from './RecipeHead/RecipeHead';
import { useMediaQuery } from '@hook/media-query';
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const RecipeContainer = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const { recipe_id, user_id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const isMobile = useMediaQuery('(max-width: 980px)');

	const {
		// User
		getUser,
		stateUser,
		loadingGetUser,

		// Recipe
		getRecipe,
		stateRecipe,
		loadingGetRecipe,

		// Cart
		getCart,
		stateCart,
		loadingGetCart,
	} = props;

	const activeTabIndex = () => {
		var isAdapt = location.pathname.endsWith('/adapt');
		var isShop = location.pathname.endsWith('/shop');

		if (!isAdapt && !isShop) {
			return true;
		}

		return false;
	};

	const activeTab = (ends) => {
		var endsWith = location.pathname.endsWith(ends);

		if (ends === '/adapt') {
			return endsWith;
		}

		if (ends === '/shop') {
			return endsWith;
		}
	};

	return (
		<>
			{!isMobile ? (
				<>
					<RecipeHead //
						profile_img={stateUser?.data?.profile_img}
						name={stateUser.data.name}
						title={stateRecipe?.data?.title}
					/>
					<div className={joinClass([c.tabs])}>
						<BtnAlt
							_={{
								value: t('Cook'),
								onClick: () => navigate(`/user/${user_id}/recipe/${recipe_id}/cook`),
							}}
							Icon={{ img: imgIconCook, className: joinClass([c['icon-cook']]) }}
							active={activeTabIndex()}
						/>
						<BtnAlt
							_={{
								value: t('Shop'),
								onClick: () => navigate(`/user/${user_id}/recipe/${recipe_id}/shop`),
							}}
							Icon={{ img: imgIconShop, className: joinClass([c['icon-shop']]) }}
							active={activeTab('/shop')}
						/>
					</div>
				</>
			) : (
				<div className={joinClass([c['header-mobile']])}>
					<h3 className={joinClass([c.title])}>
						{activeTabIndex() ? t('Cook') : null}
						{activeTab('/shop') ? t('Shop') : null}
					</h3>
					<Icon
						img={imgIconX}
						onClick={() => {
							setStore({
								...store,
								config: {
									...store.config,
									showResponsiveRecipeActions: false,
								},
							});
						}}
						className={joinClass([c.x])}
					/>
				</div>
			)}

			<Outlet
				context={[
					{
						// User
						getUser,
						stateUser,
						loadingGetUser,

						// Cook
						getRecipe,
						stateRecipe,
						loadingGetRecipe,

						// Cart
						getCart,
						stateCart,
						loadingGetCart,
					},
				]}
			/>
		</>
	);
};
