import React, { useEffect, useRef, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Libs Vìdeo
import 'video.js/dist/video-js.css';
import videojs from 'video.js/dist/video.es';
import 'videojs-youtube';

import './Video.css';
import c from './Video.module.scss';

// Img
import imgPlaceholder from '@img/default/placeholder.webp';

import imgCheck from '@icon/check.svg';
import imgIconChevLeft from '@icon/icon-chev-left.svg';
import imgIconShop from '@icon/icon-shop.svg';
import imgIconVolumeMuted from '@icon/icon-volume-mute.svg';
import imgIconVolume from '@icon/icon-volume.svg';
import imgIconBack from '@icon/x.svg';
import imgIconCook from '@img/default/logo-single.svg';

import { Icon } from '@cpnt/Icons/Icon/Icon';
import { IconVideoPlayPause } from '@cpnt/Icons/IconVideoPlay/IconVideoPlayPause';
import { Loading } from '@cpnt/Loading/Loading';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';
import { joinClass } from '@func/cpnt';
import { useMediaQuery } from '@hook/media-query';

const formatTime = (seconds) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);

	const formattedMinutes = hours > 0 ? String(minutes).padStart(2, '0') : minutes;
	const formattedSeconds = String(secs).padStart(2, '0');

	return hours > 0 ? `${hours}:${formattedMinutes}:${formattedSeconds}` : `${formattedMinutes}:${formattedSeconds}`;
};

const playerProgress = (player) => {
	const currentTime = player.currentTime();
	const duration = player.duration();
	const percentage = (currentTime / duration) * 100;
	return percentage.toFixed(2);
};

export const Video = (props) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('(max-width: 980px)');

	const {
		options, //
		onReady,
		altControlsPosition,
		placeholder,
		backLink,
		user = {},
		recipe = {},
		onClickAction,
		loading,
	} = props;

	const { profile_img, name } = user.data;
	const { title, user_id, recipe_id } = recipe.data;

	const recipeRef = useRef();

	useEffect(() => {
		recipeRef.current = recipe;
	}, [loading]);

	if (placeholder) {
		options.muted = true;
		options.autoplay = false;
	}

	const videoRef = useRef(null);
	const playerRef = useRef(null);
	const progressBarRef = useRef(null);
	const volumeBarRef = useRef(null);
	const videoPlayRef = useRef(null);

	const [progress, setProgress] = useState(0);
	const [volume, setVolume] = useState(100);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [timeDisplay, setTimeDisplay] = useState('0:00 / 0:00');

	// Control play visibility
	const [stateShowPlay, setStateShowPlay] = useState(false);
	const refContainer = useRef(null);
	const timeoutRef = useRef(null);
	const mouseStillRef = useRef(false);

	const handleMouseEnter = () => {
		if (!mouseStillRef.current) {
			setStateShowPlay(true);
			clearTimeout(timeoutRef.current);
		}
	};

	const handleMouseMove = () => {
		mouseStillRef.current = false;
		setStateShowPlay(true);
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setStateShowPlay(false);
			mouseStillRef.current = true;
		}, 2000);
	};

	const handleMouseLeave = () => {
		clearTimeout(timeoutRef.current);
		setStateShowPlay(false);
	};

	useEffect(() => {
		const container = refContainer.current;
		if (container) {
			container.addEventListener('mouseenter', handleMouseEnter);
			container.addEventListener('mousemove', handleMouseMove);
			container.addEventListener('mouseleave', handleMouseLeave);
		}
		return () => {
			if (container) {
				container.removeEventListener('mouseenter', handleMouseEnter);
				container.removeEventListener('mousemove', handleMouseMove);
				container.removeEventListener('mouseleave', handleMouseLeave);
			}
		};
	}, []);

	useEffect(() => {
		if (!loading && recipe.status !== 200) {
			const player = playerRef.current;
			player?.volume(0);
			setVolume(0);
			setIsMuted(true);
			player?.pause();
			setTimeDisplay('0:00 / 0:00');
		}
	}, [loading, recipe]);

	let isDragging = false;

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement('video-js');
			videoElement.classList.add('vjs-big-play-centered');
			videoRef.current.appendChild(videoElement);

			const player = (playerRef.current = videojs(videoElement, options, () => {
				onReady && onReady(player);

				player.on('timeupdate', () => {
					setProgress(playerProgress(player));
					const current = player.currentTime();
					const total = player.duration();
					setTimeDisplay(`${formatTime(current)} / ${formatTime(total)}`);
				});

				player.on('play', () => setIsPlaying(true));
				player.on('pause', () => setIsPlaying(false));
			}));
		}
	}, [options]);

	const handleDrag = (event, type) => {
		if (recipeRef.current.status !== 200) return;

		const player = playerRef.current;
		const rect = type === 'progress' ? progressBarRef.current.getBoundingClientRect() : volumeBarRef.current.getBoundingClientRect();
		const isVertical = type === 'volume';

		const position = isVertical ? rect.bottom - (event.touches ? event.touches[0].clientY : event.clientY) : (event.touches ? event.touches[0].clientX : event.clientX) - rect.left;

		const percentage = Math.max(0, Math.min(position / (isVertical ? rect.height : rect.width), 1));

		if (type === 'progress') {
			player.currentTime(percentage * player.duration());
			setProgress(percentage * 100);
		} else {
			player.volume(percentage);
			setVolume(percentage * 100);
			setIsMuted(percentage === 0);
		}
	};

	const stopDrag = (handleMove, type) => {
		if (recipeRef.current.status !== 200) return;

		isDragging = false;
		document.removeEventListener('mousemove', handleMove);
		document.removeEventListener('touchmove', handleMove);
		const player = playerRef.current;
		player.play();
	};

	const startDrag = (type, event) => {
		if (recipeRef.current.status !== 200) return;

		isDragging = true;
		handleDrag(event, type);

		const handleMove = (e) => handleDrag(e, type);
		document.addEventListener('mousemove', handleMove);
		document.addEventListener('touchmove', handleMove);
		document.addEventListener('mouseup', () => stopDrag(handleMove, type));
		document.addEventListener('touchend', () => stopDrag(handleMove, type));

		const player = playerRef.current;
		player.play();
	};

	const handleClick = (event, type) => {
		if (recipeRef.current.status !== 200) return;
		if (!isDragging) handleDrag(event, type);
	};

	const togglePlayPause = () => {
		if (recipeRef.current.status !== 200) return;

		const player = playerRef.current;

		if (!player.paused()) {
			player.pause();
		} else {
			player.play();

			// First click page load
			if (volume === 100) {
				player.muted(false);
				setIsMuted(false);
				setVolume(100);
			}
		}
	};

	const toggleMute = () => {
		if (recipeRef.current.status !== 200) return;

		const player = playerRef.current;
		const newMuteState = !isMuted;
		player.muted(newMuteState);
		setIsMuted(newMuteState);
		if (isMuted) {
			setVolume(100);
		} else {
			setVolume(0);
		}
	};

	const onClickActionLink = () => {
		if (recipeRef.current.status !== 200) return;

		const player = playerRef.current;
		player.pause();
		onClickAction();
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (videoRef.current && videoRef.current.contains(event.target)) {
				// console.log('<< dentro');
				togglePlayPause();
			} else {
				// console.log('fora >>');
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [videoRef]);

	return (
		<div className={joinClass([c.container, altControlsPosition ? c['alt-control-position'] : ''])} data-vjs-player>
			<div className={joinClass([c.content])}>
				<div className={joinClass([c.player])} ref={refContainer}>
					<div ref={videoRef} className={joinClass([c.video])} />
					<div className={joinClass([c.play, c.control, stateShowPlay || placeholder ? c.visible : ''])} onClick={togglePlayPause} ref={videoPlayRef}>
						{!loading && recipe.status === 200 && <IconVideoPlayPause pause={isPlaying} className={joinClass(['cursor-pointer', c['icon-play']])} />}
					</div>

					{placeholder && !isPlaying ? <div className={joinClass([c['placeholder-video']])} style={{ backgroundImage: `url(${placeholder})` }} /> : null}

					{!loading && recipe.status !== 200 ? <div className={joinClass([c['placeholder-video']])} style={{ backgroundImage: `url(${imgPlaceholder})` }} /> : null}

					{loading ? (
						<div className={joinClass([c.placeholder])}>
							<Loading show abs />
						</div>
					) : null}
				</div>

				<div className={joinClass([c.inner, c.control])}>
					<div className={joinClass([c.top])}>
						<Link to={backLink} className={joinClass([c.back, c['icon-bg']])}>
							<Icon img={isMobile ? imgIconChevLeft : imgIconBack} color="var(--color-white)" />
						</Link>
					</div>

					<div className={joinClass([c.controls])}>
						{isMobile ? (
							<div className={joinClass([c.info])}>
								<div className={joinClass([c.cta])}>
									<Link to={`/user/${user_id}/recipe/${recipe_id}/shop`} className={joinClass([c['cta-btn']])} onClick={onClickActionLink}>
										<TextIcon
											Icon={{ img: imgIconShop, color: 'var(--color-white)', className: c['cta-btn-icon'] }} //
											text={t('Shop')}
											color="var(--color-white)"
											className={joinClass([c['text-icon']])}
											vertical
										/>
									</Link>
									<Link to={`/user/${user_id}/recipe/${recipe_id}/cook`} className={joinClass([c['cta-btn']])} onClick={onClickActionLink}>
										<TextIcon
											Icon={{ img: imgIconCook, color: 'var(--color-white)', className: c['cta-btn-icon'] }} //
											text={t('Cook')}
											color="var(--color-white)"
											className={joinClass([c['text-icon']])}
											vertical
										/>
									</Link>
								</div>

								<div className={joinClass([c['info-inner']])}>
									<div className={joinClass([c['img-box']])}>
										<img src={profile_img} alt="profile-img" className={joinClass([c.img])} />
										<Icon //
											img={imgCheck}
											classNameWrap={joinClass([c['icon-wrap']])}
											color="var(--color-white)"
											wrap
										/>
									</div>
									<div className={joinClass([c.user])}>{name}</div>
								</div>
								<h4 className={joinClass([c.title])}>{title}</h4>
							</div>
						) : null}

						<div className={joinClass([c.progress])} ref={progressBarRef} onMouseDown={(e) => startDrag('progress', e)} onTouchStart={(e) => startDrag('progress', e)} onClick={(e) => handleClick(e, 'progress')}>
							<div className={joinClass([c.bar])} style={{ width: `${progress}%` }}></div>
							<div className={joinClass([c.bg])}></div>
						</div>

						<div className={joinClass([c.time])}>{timeDisplay}</div>

						<div className={joinClass([c.vol])}>
							<div className={joinClass([c['icon-volume'], c['icon-bg']])} onClick={toggleMute}>
								<Icon img={isMuted ? imgIconVolumeMuted : imgIconVolume} color="var(--color-white)" />
							</div>

							<div className={joinClass([c['volume-bar']])} ref={volumeBarRef} onMouseDown={(e) => startDrag('volume', e)} onTouchStart={(e) => startDrag('volume', e)} onClick={(e) => handleClick(e, 'volume')}>
								<div className={joinClass([c.bar])} style={{ height: `${volume}%` }}></div>
								<div className={joinClass([c.bg])}></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
