export const invalidFields = (props) => {
	const {
		//
		fields,
		validate = '*',
	} = props;

	var fieldsValidated = [];

	if (typeof fields === 'object') {
		if (validate === '*') {
			Object.keys(fields).map((val) => {
				var field = fields[val];
				if (!field || field === '') {
					fieldsValidated.push(field);
				}
			});
		} else {
			validate.map((val) => {
				var field = fields[val];
				if (!field || field === '') {
					fieldsValidated.push(field);
				}
			});
		}
	}

	if (fieldsValidated.length) {
		return fieldsValidated;
	}

	return false;
};
