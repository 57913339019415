// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes liw6x{from{left:-200%}to{left:200%}}.TwzUA{padding:var(--gap) var(--gap-content) var(--gap-content);display:flex;flex-direction:column;gap:var(--gap-small)}@media(max-width: 680px){.TwzUA{padding:var(--gap)}}.TwzUA .DRlTd{display:flex;flex-direction:column;gap:var(--gap-small-med)}.TwzUA .GNrgz{border-bottom:1px solid var(--color-border-alt);margin-bottom:-10px}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/RecipeContainer/RecipeCook/RecipeCook.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,wDAAA,CACA,YAAA,CACA,qBAAA,CACA,oBAAA,CAEA,yBAND,OAOE,kBAAA,CAAA,CAGD,cACC,YAAA,CACA,qBAAA,CACA,wBAAA,CAGD,cACC,+CAAA,CACA,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TwzUA`,
	"wrapper": `DRlTd`,
	"accordeon": `GNrgz`,
	"animationPlaceholderLoading": `liw6x`
};
export default ___CSS_LOADER_EXPORT___;
