// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bMCsh .yce0W .Jlxnv{border:1px solid var(--color-border-alt);border-radius:var(--border-radius);background-color:var(--color-white);box-shadow:var(--box-shadow-box)}.bMCsh .yce0W{display:flex;flex-wrap:wrap;gap:var(--gap-inputs)}@keyframes wUQmi{from{left:-200%}to{left:200%}}.bMCsh .yce0W .Jlxnv{padding:var(--gap-big);text-decoration:none;font-size:var(--fs-title)}.bMCsh .yce0W .Jlxnv .jFlaD{font-size:var(--gap-large)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/pages/logged/DashboardAdmin/DashboardAdmin.module.scss"],"names":[],"mappings":"AAAA,qBACC,wCAAA,CACA,kCAAA,CACA,mCAAA,CACA,gCAAA,CAED,cACC,YAAA,CACA,cAAA,CACA,qBAAA,CCoBA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CC5BD,qBAEC,sBAAA,CACA,oBAAA,CACA,yBAAA,CAEA,4BACC,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `bMCsh`,
	"links": `yce0W`,
	"link": `Jlxnv`,
	"icon": `jFlaD`,
	"animationPlaceholderLoading": `wUQmi`
};
export default ___CSS_LOADER_EXPORT___;
