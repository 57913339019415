import React from 'react';

// CSS
import c from './Info.module.scss';

// Imgs
import imgLogo from '@img/default/logo-single.svg';
import imgIconError from '@icon/x-alt.svg';
import imgIconSuccess from '@icon/icon-check.svg';

// Cpnt
import { joinClass } from '@func/cpnt';
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const Info = (props) => {
	const {
		className = c['default'], //
		type = 'cookbook',
		title,
		desc,
		Icon: _Icon
	} = props;

	var imgType;

	var defaultTypes = {
		cookbook: imgLogo,
		error: imgIconError,
		success: imgIconSuccess
	};

	if (defaultTypes[type]) {
		imgType = defaultTypes[type];
	}

	return (
		<div className={joinClass([className, c.container, c['type-' + type]])}>
			{imgType && (
				<Icon //
					{..._Icon}
					img={imgType}
					classNameWrap={joinClass([c.icon, c['icon-' + type]])}
					wrap
				/>
			)}
			{title && <h3 className={joinClass([c.title])}>{title}</h3>}
			{desc && <div className={joinClass([c.desc])}>{desc}</div>}
		</div>
	);
};
