import React, { useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const RedirectTo = (props) => {
	const { redirectTo } = props;
	const navigate = useNavigate();
	const [showInfo, setShowInfo] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (redirectTo) {
			navigate(redirectTo);
		} else {
			setShowInfo(true);
		}
	}, []);

	return <div>{showInfo ? t('Route not found') : ''}</div>;
};

export { RedirectTo };
