import React from 'react';

// Libs
import { Link } from 'react-router-dom';

// CSS
import c from './TextIcon.module.scss';

// Cpnt
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { joinClass } from '@func/cpnt';

export const TextIcon = (props) => {
	const {
		className = '', //
		Icon: _Icon,
		text,
		vertical,
		reverse,
		color,
		link,
		onClick,
	} = props;

	var classes = joinClass([className, c.container, vertical ? c.vertical : '', reverse ? c.reverse : '']);

	const Inner = () => {
		return (
			<>
				<Icon {..._Icon} />
				<span className={joinClass([c.text])} {...(color ? { style: { color: color } } : {})}>
					{text}
				</span>
			</>
		);
	};

	if (!link) {
		return (
			<div className={classes} onClick={onClick}>
				<Inner />
			</div>
		);
	}

	return (
		<Link to={link} onClick={onClick} className={classes}>
			<Inner />
		</Link>
	);
};
