import React from 'react';

// CSS
import c from './LayoutUserActions.module.scss';

// Imgs
import imgLogo from '@img/default/logo-cookbook.svg';

// Cpnt
import { joinClass } from '@func/cpnt';

export const LayoutUserActions = (props) => {
	const {
		className, //
		...rest
	} = props;

	const Cpnt = props.component;

	return (
		<div className={joinClass([className, c.container])}>
			<div className={joinClass([c['box-center']])}>
				<div className={joinClass([c.wrap])}>
					<div className={joinClass([c.left])}>
						<div className={joinClass([c['logo-wrap']])}>
							<img src={imgLogo} alt="logo" className={joinClass([c.logo])} />
						</div>
					</div>
					<div className={joinClass([c.right])}>
						<Cpnt {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};
