import React from 'react';

// CSS
import c from './LayoutRecipe.module.scss';

// Func
import { joinClass } from '@func/cpnt';

export const LayoutRecipe = (props) => {
	const { ...rest } = props;

	const Cpnt = props.component;

	return (
		<div className={joinClass([c.container])}>
			<div className={joinClass([c.inner])}>
				<div className={joinClass([c.content])}>
					<Cpnt {...rest} />
				</div>
			</div>
		</div>
	);
};
