import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';

// CSS
import c from './RecipeShop.module.scss';

// Logos
import imgLogoCookBook from '@img/default/logo-cookbook.svg';
import imgLogoInstacart from '@img/logos/instacart.png';
import imgLogoWalmart from '@img/logos/walmart.png';
import imgLogoAmazon from '@img/logos/amazon.png';
import imgLogoAmazonFresh from '@img/logos/amazon-fresh.png';
import imgLogoUberEats from '@img/logos/uber-eats.png';

// Imgs
import imgIconReload from '@icon/icon-basket.svg';

// Components
import { Accordeon } from '@cpnt/Accordeon/Accordeon';
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { Placeholder } from '@cpnt/Placeholders/Placeholder/Placeholder';
import { joinClass } from '@func/cpnt';
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';

export const RecipeShop = () => {
	const [
		{
			// User
			getUser,
			stateUser,
			loadingGetUser,

			// Cook
			getRecipe,
			stateRecipe,
			loadingGetRecipe,

			// Cart
			getCart,
			stateCart,
			loadingGetCart,
		},
	] = useOutletContext();

	const { t } = useTranslation();

	const icons = {
		cookbook: imgLogoCookBook,
		instacart: imgLogoInstacart,
		walmart: imgLogoWalmart,
		amazon: imgLogoAmazon,
		amazon_fresh: imgLogoAmazonFresh,
		uber_eats: imgLogoUberEats,
	};

	return (
		<>
			<div className={joinClass([c.container])}>
				<Accordeon title={t('Ingredients')} classNameChildren={joinClass([c['accordeon-children']])} classNameClosed={c['accordeon']}>
					{loadingGetCart ? (
						<>
							<Placeholder type="box" />
							<Placeholder type="box" />
							<Placeholder type="box" />
						</>
					) : stateCart.status !== 200 ? (
						<div>
							<div className={joinClass([c['not-available']])}>{t('Unable to load shop ingredients')}</div>
							<Btn
								_={{
									value: <TextIcon text={t('Try loading shop products again')} Icon={{ img: imgIconReload, className: c.icon }} className={c['icon-box']} />,
									onClick: getCart,
								}}
								className={joinClass([c['btn-repeat']])}
							/>
						</div>
					) : (
						<>
							<div className={joinClass([c.info])}>
								{t("You will use {{ingredients}} ingredients for this recipe. But don't worry, we've already found everything for you. Where you want to buy this ingredients?", {
									ingredients: stateCart?.data?.ingredients?.length,
								})}
							</div>
							<div className={joinClass([c['buttons']])}>
								{stateCart?.data?.ingredients?.map((val, key) => {
									var img;
									if (icons[val.store_name]) {
										img = icons[val.store_name];
									}
									return (
										<Link key={key} to={val.url} target="_blank" className={joinClass([c.btn, c['white-alt']])}>
											<div className={joinClass([c.text])}>{t('Buy with')}</div>
											{!img ? <span>{val.store_name}</span> : <Icon img={img} className={joinClass([c.icon])} color={false} vector={false} alt={val.store_name} />}
										</Link>
									);
								})}
							</div>
						</>
					)}
				</Accordeon>

				<Accordeon title={t('Tools')} classNameChildren={joinClass([c['accordeon-children']])} classNameClosed={c['accordeon']}>
					{loadingGetCart ? (
						<>
							<Placeholder type="box" />
							<Placeholder type="box" />
							<Placeholder type="box" />
						</>
					) : stateCart.status !== 200 ? (
						<div className={joinClass([c['not-available']])}>{t('Unable to load shop tools')}</div>
					) : (
						<>
							<div className={joinClass([c.info])}>
								{t("You will use {{tools}} tools for this recipe. But don't worry, we've already found everything for you. Where you want to buy this tools?", {
									tools: stateRecipe?.data?.tools?.length,
								})}
							</div>
							<div className={joinClass([c['buttons']])}>
								{stateCart?.data?.tools?.map((val, key) => {
									var img;
									if (icons[val.store_name]) {
										img = icons[val.store_name];
									}
									return (
										<Link key={key} to={val.url} target="_blank" className={joinClass([c.btn, c['white-alt']])}>
											<div className={joinClass([c.text])}>{t('Buy with')}</div>
											{!img ? <span>{val.store_name}</span> : <Icon img={img} className={joinClass([c.icon])} color={false} vector={false} alt={val.store_name} />}
										</Link>
									);
								})}
							</div>
						</>
					)}
				</Accordeon>
			</div>
		</>
	);
};
