// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mg5Y_{font-size:.9em;font-family:inherit;max-width:100%;border:0;outline:none;cursor:pointer;font-weight:inherit;position:relative;text-decoration:none;display:inline-flex;align-items:center;justify-content:center;min-width:0;z-index:1;overflow:hidden;color:inherit;white-space:break-word;gap:var(--gap)}@keyframes sStvG{from{left:-200%}to{left:200%}}.Mg5Y_{background-color:rgba(0,0,0,0);border-bottom:2px solid rgba(0,0,0,0);padding:0 0 .8em}.Mg5Y_:before{content:"";position:absolute;bottom:0;translate:-50%;left:50%;width:0;height:3px;background-color:var(--color-primary);transition:var(--transition)}.Mg5Y_.vgKMM:before,.Mg5Y_:hover:before{width:96%}.Mg5Y_ .GIcel{gap:var(--gap-inputs)}.lfkde{font-size:1.4em}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_buttons.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Btns/BtnIcon/BtnAlt.module.scss"],"names":[],"mappings":"AAGA,OACC,cAAA,CACA,mBAAA,CACA,cAAA,CACA,QAAA,CACA,YAAA,CACA,cAAA,CACA,mBAAA,CACA,iBAAA,CACA,oBAAA,CACA,mBAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,SAAA,CACA,eAAA,CACA,aAAA,CACA,sBAAA,CACA,cAAA,CCQA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OAEC,8BAAA,CACA,qCAAA,CACA,gBAAA,CAEA,cACC,UAAA,CACA,iBAAA,CACA,QAAA,CACA,cAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,qCAAA,CACA,4BAAA,CAGD,wCAEC,SAAA,CAGD,cACC,qBAAA,CAIF,OACC,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Mg5Y_`,
	"active": `vgKMM`,
	"text-container": `GIcel`,
	"icon": `lfkde`,
	"animationPlaceholderLoading": `sStvG`
};
export default ___CSS_LOADER_EXPORT___;
