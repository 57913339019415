import api from 'axios';
import { config, validateReturn } from '@api/axios';

const apiRecipes = {
	post: async (data) => {
		return await api
			.post(`/recipes`, data, config())
			.then((ret) => {
				return validateReturn(ret);
			})
			.catch((ret) => {
				return validateReturn(ret);
			});
	},
};

export { apiRecipes };
