import React from 'react';

// CSS
import c from './Placeholder.module.scss';

// Cpnt
import { joinClass } from '@func/cpnt';

export const Placeholder = ({ type = 'default', className, width, height, radius }) => {
	var style = {};

	if (width) {
		style.width = width;
	}

	if (height) {
		style.height = height;
	}

	return (
		<div //
			className={joinClass([c.placeholder, c['type-' + type], className, radius ? c.radius : ''])}
			style={style}
		/>
	);
};
