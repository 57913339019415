import React, { useState } from 'react';

// CSS
import c from './Select.module.scss';

// Func
import { joinClass } from '@func/cpnt';
import { hash } from '@func/generate';
import { Loading } from '@cpnt/Loading/Loading';

export const Select = (props) => {
	const {
		className = '', //
		_,
		label,
		classNameLabel,
		options = [],
		selected,
		type = 'default',
		loading,
	} = props;

	const [id] = useState(hash('select-id-'));
	var typeSelect = c[type];

	return (
		<div className={joinClass([className, c.container])}>
			<Loading abs show={loading} />
			{label && (
				<label htmlFor={`${id}`} className={joinClass([c.label, classNameLabel])}>
					{label}
				</label>
			)}
			<select id={`${id}`} {..._} className={joinClass([typeSelect, _?.className, c.select])}>
				{options &&
					options?.map((val, key) => {
						return (
							<option //
								key={key}
								value={val.value}
								{...(selected || val.selected ? (selected === val.value ? { selected: true } : {}) : {})}
								{...(val.selected ? { defaultValue: true } : {})}
								{...(val.disabled ? { disabled: true } : {})}
							>
								{val.label}
							</option>
						);
					})}
			</select>
		</div>
	);
};
