import React from 'react';

// CSS
import c from './LayoutPage.module.scss';

// Cpnt
import { Header } from '@cpnt/Header/Header';
import { joinClass } from '@func/cpnt';

export const LayoutPage = (props) => {
	const {
		className, //
		...rest
	} = props;

	const Cpnt = props.component;

	return (
		<div className={joinClass([className, c.container])}>
			<Header hideMenu />
			<Cpnt {...rest} />
		</div>
	);
};
