export const STORE_SESSION_OBJ = {
	token: false,
	user: {},
	config: {
		hideSidebar: true,
		showResponsiveRecipeActions: false
	},
	cart: {}
};

export const IMG_PLACEHOLDER = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ID+ZccAAAAASUVORK5CYII=';
