// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes evv0v{from{left:-200%}to{left:200%}}.nMOzA{display:inline-flex;flex-direction:column;text-align:center;align-items:center;gap:var(--gap)}.nMOzA .PGrCj{border-radius:50%;box-shadow:var(--box-shadow-box);overflow:hidden;max-width:180px;border:5px solid var(--color-border);text-decoration:none}.nMOzA .PGrCj .BTTgm{display:block;width:100%}.nMOzA .wWoC_{font-size:var(--fs-title-small);font-weight:500;text-align:center;color:var(--color-text);text-decoration:none;word-break:break-word}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/UserActions/UserItem/UserItem.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,mBAAA,CACA,qBAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CAEA,cACC,iBAAA,CACA,gCAAA,CACA,eAAA,CACA,eAAA,CACA,oCAAA,CACA,oBAAA,CAEA,qBACC,aAAA,CACA,UAAA,CAIF,cACC,+BAAA,CACA,eAAA,CACA,iBAAA,CACA,uBAAA,CACA,oBAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nMOzA`,
	"img-box": `PGrCj`,
	"img": `BTTgm`,
	"name": `wWoC_`,
	"animationPlaceholderLoading": `evv0v`
};
export default ___CSS_LOADER_EXPORT___;
