// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes QnN3G{from{left:-200%}to{left:200%}}.g0fPr{display:grid;grid-template-columns:1fr minmax(540px, 540px);height:100vh;max-height:100vh;overflow:hidden}@media(max-width: 1200px){.g0fPr{grid-template-columns:1fr minmax(400px, 400px)}}.g0fPr .bv8tk{position:relative;display:flex;align-items:center;justify-content:center;background-color:var(--color-primary);background-size:cover;background-position:center center;background-repeat:no-repeat;max-height:100vh}.g0fPr .bv8tk .MzQbi{position:absolute;inset:0;backdrop-filter:blur(15px);background-color:var(--color-black-alpha-2);z-index:2;pointer-events:none}.g0fPr .RtgOM{background-color:var(--color-bg);max-height:100vh;overflow-y:auto;position:relative;display:flex;flex-direction:column}@media(max-width: 980px){.g0fPr{display:grid;grid-template-columns:1vw 1vw}.g0fPr .bv8tk,.g0fPr .RtgOM{inset:0;position:fixed;width:100vw;height:100vh}.g0fPr .RtgOM{z-index:2;left:100%;transition:left .3s ease 0s}.g0fPr .RtgOM.RTH1P{left:0}.g0fPr .bv8tk{z-index:1}}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/pages/notLogged/Recipe/Recipe.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,YAAA,CACA,8CAAA,CACA,YAAA,CACA,gBAAA,CACA,eAAA,CAEA,0BAPD,OAQE,8CAAA,CAAA,CAGD,cACC,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qCAAA,CACA,qBAAA,CACA,iCAAA,CACA,2BAAA,CACA,gBAAA,CAEA,qBACC,iBAAA,CACA,OAAA,CACA,0BAAA,CACA,2CAAA,CACA,SAAA,CACA,mBAAA,CAGF,cACC,gCAAA,CACA,gBAAA,CACA,eAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CAIF,yBACC,OACC,YAAA,CACA,6BAAA,CAEA,4BAEC,OAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CAED,cACC,SAAA,CACA,SAAA,CACA,2BAAA,CAEA,oBACC,MAAA,CAGF,cACC,SAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `g0fPr`,
	"left": `bv8tk`,
	"overlay": `MzQbi`,
	"right": `RtgOM`,
	"right-active": `RTH1P`,
	"animationPlaceholderLoading": `QnN3G`
};
export default ___CSS_LOADER_EXPORT___;
