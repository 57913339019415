import { URL_API, API_REQUEST_TIMEOUT, API_SHOW_REQUEST_CONSOLE, API_REQUEST_TIMEOUT_UPLOAD, CONTACT_EMAIL } from '@config/config';
import { isLogged } from '@func/auth';

export const config = (formData) => {
	const logged = isLogged();

	var token = {};

	if (logged) {
		if (logged.token !== null && logged.token !== undefined && logged.token !== '') {
			token = {
				Authorization: `Bearer ${logged.token}`,
			};
		}
	}

	var headers = {
		'Content-Type': 'application/json',
		...token,
	};

	if (formData) {
		headers['Content-Type'] = 'multipart/form-data';
	}

	var axiosConfig = {
		baseURL: URL_API,
		timeout: formData ? API_REQUEST_TIMEOUT_UPLOAD : API_REQUEST_TIMEOUT,
		headers: headers,
	};

	return axiosConfig;
};

export const validateReturnAxios = (ret) => {
	var retJSON = {};
	try {
		if (ret.request.data !== undefined) {
			retJSON = ret.request.data;
		} else if (ret.request.response !== undefined) {
			if (typeof ret.request.response === 'object') {
				retJSON = ret.request.response;
			} else {
				retJSON = JSON.parse(ret.request.response);
				if (retJSON && typeof retJSON !== 'object') {
					retJSON = JSON.parse(retJSON);
				}
			}
		} else if (ret.request._response !== undefined) {
			if (typeof ret.request._response === 'object') {
				retJSON = ret.request._response;
			} else {
				retJSON = JSON.parse(ret.request._response);
			}
		}
	} catch (error) {
		if (ret.request.response === '') {
			retJSON = {
				message: (
					<>
						Unable to get a response from the API, please try submitting again.
						<br />
						<br />
						If the error persists, please inform us of the problem via email{' '}
						<strong>
							<a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
						</strong>
						.
					</>
				),
			};
		} else if (typeof ret.request.response === 'string') {
			retJSON = { message: ret.request.response };
		} else {
			retJSON = {};
		}
	}

	var error;

	if (!ret.request.data) {
		error = ret?.request?.statusText;
		if (error === '') {
			error = 'Unable to get a response from the API, please try submitting again.';
		}
	}

	if (retJSON[0]) {
		return {
			data: retJSON,
			status: ret.request.status,
			statusText: ret.request.statusText,
			error,
		};
	}

	if (ret.request.status === 0) {
		return {
			...retJSON,
			status: 504,
			statusText: 'Unable to get a response from the API, please try submitting again.',
			error,
		};
	}

	return {
		data: retJSON,
		status: ret.request.status,
		statusText: ret.request.statusText,
		error,
	};
};

export const validateReturn = (ret) => {
	const retJSON = validateReturnAxios(ret);

	if (API_SHOW_REQUEST_CONSOLE) {
		var dataSent = ret && ret.config && ret.config.data;
		try {
			dataSent = JSON.parse(dataSent);
		} catch (error) {
			//
		}

		var statusColor;
		const status = retJSON.status;

		// Status
		if (status >= 200 && status <= 299) {
			statusColor = 'green';
		} else if (status >= 300 && status <= 399) {
			statusColor = 'yellow';
		} else {
			statusColor = 'red';
		}

		// Method
		var methodColor;
		const method = ret.config.method;

		if (method === 'get') {
			methodColor = '#61AFFE';
		} else if (method === 'post') {
			methodColor = '#49CC90';
		} else if (method === 'put') {
			methodColor = '#FCA130';
		} else if (method === 'delete') {
			methodColor = '#F93E3E';
		} else {
			methodColor = '#000';
		}

		console.groupCollapsed(`%c.%c${method.toUpperCase()}%c${ret.config.url}`, 'background-color: ' + statusColor + '; border-radius: 50%; display: inline-flex; width: 13px; height: 13px; color: transparent;', 'color: #FFF; padding: 2px 3px; margin-left: 5px; border-radius: 3px; color: ' + methodColor + '; ', 'margin-left: 5px', dataSent ? dataSent : ' ');
		retJSON && console.log(retJSON);
		console.groupEnd();
	}
	return retJSON;
};
