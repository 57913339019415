// Local
export const LOCAL_STORAGE_SESSION_KEY = 'cookbook-v2';

// API
export const URL_API = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8080/api' : 'https://cookbook-mvp-435406.uc.r.appspot.com/api';
export const API_REQUEST_TIMEOUT = 600000; // 10 minutes
export const API_REQUEST_TIMEOUT_UPLOAD = 600000; // 60.000 = 1 minute
export const API_SHOW_REQUEST_CONSOLE = true;

// Contact
export const CONTACT_EMAIL = 'contact@usecookbook.com';
