import React from 'react';

// Internationalization
import '@lng/i18n';

import { Routes } from './routes';

export const App = () => {
	return <Routes />;
};
