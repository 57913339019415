import React from 'react';

// CSS
import c from './BtnAlt.module.scss';

// Cpnt
import { Loading } from '@cpnt/Loading/Loading';
import { joinClass } from '@func/cpnt';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';

export const BtnAlt = (props) => {
	const {
		className = '', //
		loading,
		active,
		Icon: _Icon,
		_ = {}
	} = props;

	var color = active ? 'var(--color-primary)' : 'var(--color-text-3)';

	return (
		<button {..._} className={joinClass([className, c.container, active ? c.active : '', _?.className])}>
			<Loading abs show={loading} />
			<TextIcon //
				text={_.value}
				color={color}
				Icon={{ ..._Icon, color, className: joinClass([c.icon, _Icon?.className]) }}
				className={c['text-container']}
			/>
		</button>
	);
};
