// prettier-ignore
export const EN_US = {
	"time": {
        "hoursMinutes": "{{hours}}h {{minutes}}min",
        "minutes": "{{minutes}} minutes",
        "seconds": "{{seconds}} seconds"
    },
	"About": "About",
	"Recipes": "Recipes",
	"Minute": "Minute",
	"Minutes": "Minutes",
	"Please wait...": "Please wait...",
}
