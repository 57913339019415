// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._rO_y .oAN8w{display:flex;align-items:center;justify-content:space-between;gap:var(--gap-inputs)}._rO_y .oAN8w{font-size:14px;font-weight:600}._rO_y .ki800{position:relative;max-width:100%;overflow:hidden;border-radius:inherit;background-color:var(--color-primary-light-alt);z-index:10;width:100%;height:100%;border-radius:var(--border-radius);left:0;top:0}._rO_y .ki800:before{content:"";position:absolute;left:0;right:0;top:0;bottom:0;width:200%;height:200%;background:linear-gradient(to right, transparent, var(--color-primary-alpha-5), transparent);animation:sQNop 1s linear 0s;animation-iteration-count:infinite}@keyframes sQNop{from{left:-200%}to{left:200%}}._rO_y{position:relative;background-color:var(--color-white);border-radius:var(--border-radius);border:1px solid var(--color-primary-alpha-2);padding:var(--gap-small) var(--gap)}._rO_y .ki800{position:absolute;z-index:2}._rO_y .oAN8w{display:inline-flex}._rO_y .KCjrC{font-size:var(--fs-small)}._rO_y .P0A1n{font-size:var(--fs-min)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_texts.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/RecipeContainer/RecipeCook/RecipeCookItem/RecipeCookItem.module.scss"],"names":[],"mappings":"AA4BA,cACC,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBAAA,CC5BD,cACC,cAAA,CACA,eAAA,CCND,cACC,iBAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CACA,+CAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,kCAAA,CAEA,MAAA,CACA,KAAA,CAEA,qBACC,UAAA,CACA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,4FAAA,CAEA,4BAAA,CACA,kCAAA,CAGD,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,iBAAA,CACA,mCAAA,CACA,kCAAA,CACA,6CAAA,CACA,mCAAA,CAEA,cAEC,iBAAA,CACA,SAAA,CAGD,cAGC,mBAAA,CAGD,cACC,yBAAA,CAGD,cACC,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_rO_y`,
	"box": `oAN8w`,
	"placeholder": `ki800`,
	"animationPlaceholderLoading": `sQNop`,
	"desc": `KCjrC`,
	"tag": `P0A1n`
};
export default ___CSS_LOADER_EXPORT___;
