import React, { useEffect, useState } from 'react';

// Libs
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

// CSS
import c from './User.module.scss';

// Imanges
import imgIconRecipes from '@icon/icon-cam.svg';
import imgIconCheck from '@icon/icon-check.svg';
import imgIconChevLeft from '@icon/icon-chev-left.svg';
import imgIconInstagram from '@icon/icon-instagram.svg';
import imgIconYouTube from '@icon/icon-youtube.svg';
import imgIconTikTok from '@icon/icone-tiktok.svg';

// Func
import { api } from '@api/api';
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { Tag } from '@cpnt/Tag/Tag';
import { joinClass } from '@func/cpnt';
import { defaultState } from '@func/request';
import { useSecondsToTime } from '@hook/date';

// Temp
import { BtnAlt } from '@cpnt/Btns/BtnIcon/BtnAlt';
import { Img } from '@cpnt/Imgs/Img/Img';
import { Info } from '@cpnt/Info/Info';
import { PlaceholderTitleDesc } from '@cpnt/Placeholders/PlaceholderTitleDesc/PlaceholderTitleDesc';
import { RecipeVideoItem } from '@cpnt/RecipeVideoItem/RecipeVideoItem';
import { Sidebar } from '@cpnt/Sidebar/Sidebar';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';

export const User = (props) => {
	const { t } = useTranslation();
	const secondsToTime = useSecondsToTime();
	const { user_id } = useParams();

	const [loading, setLoading] = useState(true);
	const [loadingGetRecipes, setLoadingGetRecipes] = useState(true);

	const [stateUser, setStateUser] = useState(defaultState());
	const [stateRecipes, setStateRecipes] = useState(defaultState());

	const getUser = async () => {
		setLoading(true);

		const req = await api.users.id.get({ userId: user_id });

		setStateUser({
			...stateUser,
			...req,
		});

		setLoading(false);
	};

	const getRecipes = async () => {
		setLoadingGetRecipes(true);

		const req = await api.users.id.recipes.get({ userId: user_id });

		setStateRecipes({
			...stateUser,
			...req,
		});

		setLoadingGetRecipes(false);
	};

	useEffect(() => {
		getUser();
		getRecipes();
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className={joinClass([c.container])}>
			<Sidebar //
				{...props}
				user={stateUser}
				recipe={stateRecipes}
			/>
			<div className={joinClass([c['back-box']])}>
				<Link to="/" className={joinClass([c.back])}>
					<Icon img={imgIconChevLeft} className={joinClass([c.icon])} />
				</Link>
			</div>
			<div className={joinClass([c.intro])}>
				{!loading && stateUser.status !== 200 ? (
					<div>
						<Info title={t('User not found')} desc={t('Unable to load user data, try again...')} />
					</div>
				) : (
					<>
						<div className={joinClass([c['photo-box']])}>
							<Img //
								src={stateUser.data.profile_img}
								alt="profile_img"
								Placeholder={{
									show: loading,
									type: 'circle',
								}}
							/>
						</div>
						<div className={joinClass([c['name']])}>
							{loading ? (
								<PlaceholderTitleDesc className={joinClass([c['placeholder-name']])} />
							) : (
								<>
									<span>{stateUser.data.name}</span>
									<div className={joinClass([c.icon])}>
										<Icon img={imgIconCheck} color="var(--color-white)" />
									</div>
								</>
							)}
						</div>
						<div className={joinClass([c['desc']])}>{stateUser.data.intro}</div>
						{!_.isEmpty(stateUser?.data?.social_medias) ? (
							<div className={joinClass([c['social']])}>
								{!_.isEmpty(stateUser.data.social_medias.instagram) && (
									<a href={stateUser.data.social_medias.instagram} target="_blank" rel="noreferrer" className={joinClass([c.link])}>
										<Tag Icon={{ img: imgIconInstagram, color: 'var(--color-black)' }} type="4" text="Instagram" />
									</a>
								)}
								{!_.isEmpty(stateUser.data.social_medias.you_tube) && (
									<a href={stateUser.data.social_medias.you_tube} target="_blank" rel="noreferrer" className={joinClass([c.link])}>
										<Tag Icon={{ img: imgIconYouTube, color: 'var(--color-black)' }} type="4" text="YouTube" />
									</a>
								)}
								{!_.isEmpty(stateUser.data.social_medias.tik_tok) && (
									<a href={stateUser.data.social_medias.tik_tok} target="_blank" rel="noreferrer" className={joinClass([c.link])}>
										<Tag Icon={{ img: imgIconTikTok, color: 'var(--color-black)' }} type="4" text="TikTok" />
									</a>
								)}
							</div>
						) : null}
					</>
				)}
			</div>
			<div className={joinClass([c['recipes-container']])}>
				<div className={joinClass([c.tabs])}>
					<BtnAlt
						_={{
							value: t('Recipes'),
						}}
						Icon={{ img: imgIconRecipes }}
						active={true}
					/>
				</div>

				{!loadingGetRecipes && stateRecipes.status !== 200 ? (
					<div className={joinClass([c['no-content']])}>
						<div className="base">
							<div className="base-content">
								<h3>{stateRecipes.status === 404 ? t('This user does not have any recipes yet') : t('Unable to load Recipes')}</h3>
								<div>{stateRecipes.status === 404 ? t('Check back soon to see if the user has added any new recipes.') : t('Please try again...')}</div>
							</div>
						</div>
					</div>
				) : (
					<div className={joinClass([c.recipes])}>
						<div className="base">
							<div className="base-content">
								<div className={joinClass([c['recipes-loop']])}>
									{loadingGetRecipes ? (
										<>
											<RecipeVideoItem placeholder />
											<RecipeVideoItem placeholder />
											<RecipeVideoItem placeholder />
										</>
									) : stateUser.status === 200 ? (
										stateRecipes.data.map((val, key) => {
											return (
												<RecipeVideoItem
													key={key} //
													link={`/user/${user_id}/recipe/${val.recipe_id}/shop`}
													title={val.title}
													img={val.thumbnail}
													ingredients={val?.ingredients?.length}
													time={secondsToTime(val.length)}
													recipeId={val.recipe_id}
													userId={user_id}
												/>
											);
										})
									) : (
										<div>No content...</div>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
