import { useState, useEffect } from 'react';

/**
 * Hook que verifica se a tela corresponde à media query fornecida.
 * @param {string} query - A media query no formato CSS ex: '(min-width: 200px)'.
 * @returns {boolean} - Retorna true se a condição for satisfeita, false caso contrário.
 */
export const useMediaQuery = (query) => {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query);

		const handleChange = (event) => {
			setMatches(event.matches);
		};

		mediaQueryList.addEventListener('change', handleChange);
		setMatches(mediaQueryList.matches); // Verifica na inicialização

		return () => mediaQueryList.removeEventListener('change', handleChange);
	}, [query]);

	return matches;
};
