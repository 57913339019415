import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// CSS
import c from './DashboardAdmin.module.scss';

// Imgs
import imgIconRecipes from '@icon/icon-cam.svg';
import imgIconUsers from '@icon/icon-user.svg';

// Func
import { joinClass } from '@func/cpnt';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';

export const DashboardAdmin = () => {
	const { t } = useTranslation();

	return (
		<div className={joinClass([c.container])}>
			<div className={joinClass(['base'])}>
				<div className={joinClass(['base-content'])}>
					<h3>{t('Dashboard Admin')}</h3>
					<div className={joinClass([c.links])}>
						<Link className={joinClass([c.link])} to="/admin/users">
							<TextIcon text={t('Create user')} Icon={{ img: imgIconUsers, className: c.icon }} vertical />
						</Link>
						<Link className={joinClass([c.link])} to="/admin/recipes">
							<TextIcon text={t('Create recipe')} Icon={{ img: imgIconRecipes, className: c.icon }} vertical />
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
