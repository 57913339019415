import { toast as msgToast } from 'react-toastify';

import { STORE_SESSION_OBJ } from '@config/consts';

/*
 ** Messages
 ** ================================================== */
export const msg = msgToast;

/**
 * Join array in Classes String
 *
 * @param {Array} Array
 * @returns {String} strings contains classes
 *
 * @example
 * // How to use
 * var classes = joinClass(['className', 'className2', ...])
 *
 * // Returns
 * 'className className2'
 */
export const joinClass = (classes = []) => {
	var classesString = classes.filter(Boolean).join(' ');
	if (typeof classesString === 'string') {
		return classesString.trim();
	}
	return '';
};

export const toggleSidebar = ({ store, setStore }) => {
	setStore({
		...STORE_SESSION_OBJ,
		...store,
		config: {
			...store.config,
			hideSidebar: store?.config?.hideSidebar ? false : true
		}
	});
};

export const hideSidebar = ({ store, setStore }) => {
	setStore({
		...STORE_SESSION_OBJ,
		...store,
		config: {
			...store.config,
			hideSidebar: true
		}
	});
};
