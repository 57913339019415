import { useRoutes } from 'react-router-dom';

// Layouts
import { LayoutLogged } from '@role/Layouts/LayoutLogged/LayoutLogged';

// Sidebar
import { SIDEBAR_ADMIN } from '@role/Sidebar/SidebarAdmin';

// Pages
import { ManageRecipes } from '@page/logged/ManageRecipes/ManageRecipes';
import { ManageUsers } from '@page/logged/ManageUsers/ManageUsers';

// Routes
import { RedirectTo } from '@page/general/RedirectTo/RedirectTo';
import { DashboardAdmin } from '@page/logged/DashboardAdmin/DashboardAdmin';
import { ROUTES_GENERAL } from './ScreensGeneral';

export const ROUTES_ADMIN = [
	{
		path: '/login',
		element: <RedirectTo redirectTo="/admin" />,
	},
	{
		path: '/admin',
		element: (
			<LayoutLogged
				meta={{
					title: 'Dashboard Admin',
				}}
				sidebar={SIDEBAR_ADMIN}
				component={DashboardAdmin}
			/>
		),
	},
	{
		path: '/admin/users',
		element: (
			<LayoutLogged
				meta={{
					title: 'Users',
				}}
				component={ManageUsers}
				sidebar={SIDEBAR_ADMIN}
			/>
		),
	},
	{
		path: '/admin/recipes',
		element: (
			<LayoutLogged
				meta={{
					title: 'Recipes',
				}}
				component={ManageRecipes}
				sidebar={SIDEBAR_ADMIN}
			/>
		),
	},
];

function ScreensAdmin() {
	const routesAdmin = useRoutes([...ROUTES_ADMIN, ...ROUTES_GENERAL]);
	return routesAdmin;
}

export { ScreensAdmin };
