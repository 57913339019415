// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KIWuQ input,.KIWuQ textarea{box-sizing:border-box;width:100%;padding:var(--pading-input-v) var(--pading-input-h);border-radius:var(--border-radius);outline:none;transition:var(--transition);font-family:inherit;font-size:1em}.KIWuQ input,.KIWuQ textarea{display:block}.KIWuQ input.EPtfQ,.KIWuQ textarea.EPtfQ{border:1px solid var(--color-primary-alpha-7);background-color:var(--color-white)}.KIWuQ input.EPtfQ:hover,.KIWuQ textarea.EPtfQ:hover,.KIWuQ input.EPtfQ:focus,.KIWuQ textarea.EPtfQ:focus{border-color:var(--color-primary-alpha-9);box-shadow:var(--box-shadow)}.KIWuQ input.dqZLL,.KIWuQ textarea.dqZLL{border:1px solid var(--color-border);background-color:var(--color-white)}.KIWuQ input.dqZLL:hover,.KIWuQ textarea.dqZLL:hover,.KIWuQ input.dqZLL:focus,.KIWuQ textarea.dqZLL:focus{border-color:var(--color-primary-alpha-5);box-shadow:0 3px 15px var(--color-primary-alpha-3);background-color:var(--color-white)}.KIWuQ label{color:var(--color-text-2);margin-bottom:var(--gap-min);font-size:var(--fs-label)}@keyframes Joo94{from{left:-200%}to{left:200%}}.KIWuQ{display:inline-flex;flex-direction:column}.KIWuQ textarea{resize:none}.KIWuQ textarea.EJh1N{resize:vertical}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_inputs.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Inputs/Input/Input.module.scss"],"names":[],"mappings":"AAAA,6BACC,qBAAA,CACA,UAAA,CACA,mDAAA,CACA,kCAAA,CACA,YAAA,CACA,4BAAA,CACA,mBAAA,CACA,aAAA,CAGD,6BAEC,aAAA,CAEA,yCACC,6CAAA,CACA,mCAAA,CAEA,0GAEC,yCAAA,CACA,4BAAA,CAIF,yCACC,oCAAA,CACA,mCAAA,CAEA,0GAEC,yCAAA,CACA,kDAAA,CACA,mCAAA,CAKH,aACC,yBAAA,CACA,4BAAA,CACA,yBAAA,CCbA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,mBAAA,CACA,qBAAA,CAOA,gBACC,WAAA,CAGD,sBACC,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KIWuQ`,
	"default": `EPtfQ`,
	"alt": `dqZLL`,
	"resize": `EJh1N`,
	"animationPlaceholderLoading": `Joo94`
};
export default ___CSS_LOADER_EXPORT___;
