import React from 'react';

// CSS
import c from './Tag.module.scss';

// Funcs
import { joinClass } from '@func/cpnt';
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const Tag = (props) => {
	const {
		className = '', //
		text,
		onClick,
		Icon: _Icon,
		type = 'default'
	} = props;

	return (
		<div className={joinClass([c.container, className, onClick ? c.click : '', c['type-' + type]])} onClick={onClick}>
			{_Icon ? <Icon {..._Icon} /> : null}
			{text ? <span className={joinClass([c.text])}>{text}</span> : null}
		</div>
	);
};
