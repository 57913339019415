import React from 'react';

// CSS
import c from './IconVideoPlayPause.module.scss';

// Imgs
import imgIconPlay from '@icon/icon-play.svg';
import imgIconPause from '@icon/icon-pause.svg';

// Funcs
import { joinClass } from '@func/cpnt';

// Cpnt
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const IconVideoPlayPause = (props) => {
	const {
		className = '', //
		onClick,
		color = 'var(--color-white)',
		pause
	} = props;

	return (
		<div className={joinClass([c.container, className])} onClick={onClick}>
			<Icon img={pause ? imgIconPause : imgIconPlay} color={color} className={joinClass([c.icon])} />
		</div>
	);
};
