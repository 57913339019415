import React from 'react';

// CSS
import c from './PlaceholderTitleDesc.module.scss';

// Cpnt
import { Placeholder } from '@cpnt/Placeholders/Placeholder/Placeholder';

// Func
import { joinClass } from '@func/cpnt';

export const PlaceholderTitleDesc = ({ className, type = 'default' }) => {
	return (
		<div className={joinClass([c.container, className])}>
			<Placeholder type="title" className={joinClass([c.title])} width="200px" />
			<div className={joinClass([c.desc])}>
				<Placeholder type="desc" width="90%" />
				<Placeholder type="desc" width="100%" />
				<Placeholder type="desc" width="80%" />
				<Placeholder type="desc" width="70%" />
			</div>
		</div>
	);
};
