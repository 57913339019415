// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes L43Y7{from{left:-200%}to{left:200%}}.j5Ltj{background-color:var(--color-primary-light-alt)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Imgs/Img/Img.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,+CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": `j5Ltj`,
	"animationPlaceholderLoading": `L43Y7`
};
export default ___CSS_LOADER_EXPORT___;
