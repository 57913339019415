import React from 'react';

// CSS
import c from './Btn.module.scss';

// Cpnt
import { Loading } from '@cpnt/Loading/Loading';
import { joinClass } from '@func/cpnt';

export const Btn = (props) => {
	const {
		className = '', //
		loading,
		type = 'default',
		wide,
		before,
		after,
		size = 'default',
		_,
	} = props;

	var typeButton = c[type];
	var sizeButton = c[size];

	return (
		<button {..._} className={joinClass([className, c.container, typeButton, wide ? c.wide : '', _?.className, sizeButton, loading ? c['container-loading'] : ''])}>
			<Loading abs show={loading} />
			{loading && <div className={joinClass([c.loading, typeButton])} />}
			{before}
			<div className={joinClass([c.text])}>{_?.value}</div>
			{after}
		</button>
	);
};
