import React from 'react';

// Libs
import { Link } from 'react-router-dom';

// Func
import { msg } from '@func/cpnt';

/*
 ** Verifica o retorno e exibe as mensagens, agrupa, etc.
 ** ================================================== */
export const msgs = (arrReturnApi, showOnSuccess = false) => {
	var invalidFieldsToReturn = [];

	if (arrReturnApi.length > 0) {
		arrReturnApi.map((valueRequest) => {
			if (valueRequest.status >= 200 && valueRequest.status <= 299) {
				if (showOnSuccess) {
					msg.success(valueRequest.message);
				}
			} else if (valueRequest.status >= 400) {
				// Campos inválidos
				if (typeof valueRequest.invalidFields === 'object') {
					var msgGrouped = [];
					valueRequest.invalidFields.map((value, key) => {
						invalidFieldsToReturn.push([value.param]);
						msgGrouped.push(
							<div key={key} className={`${valueRequest.invalidFields.length === 1 ? '' : 'msgs-lista-item msgs-lista-item-error'}`}>
								{value.msg}
							</div>,
						);
						return false;
					});
					msg.error(<div className="msgs-lista">{msgGrouped}</div>);
				} else if (valueRequest.status === 401) {
					msg.error(
						<div className="msgs-lista">
							<div>{valueRequest.error}</div>
							<div className="margin-t-15">Caso o erro persista, tente deslogar e logar novamente.</div>
							<div className="margin-t-15">
								Se preferir,{' '}
								<Link className="strong a underline" to="/logout">
									clique aqui
								</Link>{' '}
								para acessar a tela de login.
							</div>
						</div>,
					);
				} else if (valueRequest.error !== undefined) {
					msg.error(<div className="msgs-lista">{valueRequest.error}</div>);
				} else if (valueRequest.status >= 400 && valueRequest.status <= 499) {
					msg.error(<div className="msgs-lista">{valueRequest.message}</div>);
				} else if (valueRequest.status >= 500) {
					if (valueRequest.error) {
						msg.error(<div className="msgs-lista">{valueRequest.error}</div>);
					} else if (valueRequest.exception) {
						msg.error(<div className="msgs-lista">{valueRequest.exception}</div>);
					} else {
						msg.error(<div className="msgs-lista">Unable to get a response from the API.</div>);
					}
				}
			} else if (valueRequest.status === 0) {
				msg.error(valueRequest.message);
			} else {
				msg.warning(valueRequest.error);
			}
		});
	}

	return invalidFieldsToReturn;
};

/*
 ** Verifica o retorno do get da API, trata a mensagem, e verifica o status
 ** ================================================== */
export const defaultState = (retRequestApi = {}, extraParam = {}) => {
	var ret = {
		status: false,
		data: false,
		msg: 'Unknown',
	};

	return {
		...ret,
		...extraParam,
	};
};
