import React from 'react';

// CSS
import c from './Img.module.scss';

// Img
import imgPlaceholder from '@img/default/blank.png';

// Placeholder
import { Placeholder } from '@cpnt/Placeholders/Placeholder/Placeholder';
import { joinClass } from '@func/cpnt';

export const Img = (props) => {
	const { src } = props;

	const {
		Placeholder: _Placeholder, //
		...rest
	} = props;

	if (_Placeholder?.show) {
		return <Placeholder {..._Placeholder} />;
	}

	if (!src || typeof src !== 'string' || src.length <= 3) {
		return <img {...rest} src={imgPlaceholder} className={joinClass([c.img, rest?.className])} />;
	}
	return <img {...rest} />;
};
