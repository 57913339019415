// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ZIhQe{from{left:-200%}to{left:200%}}.LiK9N{display:flex;flex-direction:column;gap:var(--gap-content);align-items:center}.LiK9N .J89aF{display:flex;flex-direction:column;width:100%;align-items:center;gap:var(--gap)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Placeholders/PlaceholderTitleDesc/PlaceholderTitleDesc.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,cACC,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LiK9N`,
	"desc": `J89aF`,
	"animationPlaceholderLoading": `ZIhQe`
};
export default ___CSS_LOADER_EXPORT___;
