import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useSecondsToTime = () => {
	const { t } = useTranslation();

	function secondsToTime(seconds) {
		const duration = moment.duration(seconds, 'seconds');
		const hours = duration.hours();
		const minutes = duration.minutes();
		const totalSeconds = duration.seconds();

		if (hours > 0) {
			return t('time.hoursMinutes', {
				hours: hours,
				minutes: minutes > 0 ? minutes : ''
			}).trim();
		} else if (minutes > 0) {
			return t('time.minutes', { minutes });
		} else {
			return t('time.seconds', { seconds: totalSeconds });
		}
	}

	return secondsToTime;
};
