import React, { useState } from 'react';

// CSS
import c from './Input.module.scss';

// Func
import { joinClass } from '@func/cpnt';
import { hash } from '@func/generate';

export const Input = (props) => {
	const {
		className = '', //
		classNameLabel,
		_,
		type = 'default',
		label,
	} = props;

	const [id] = useState(hash('input-id-'));

	var typeInput = c[type];

	return (
		<div className={joinClass([className, c.container])}>
			{label && (
				<label htmlFor={`${id}`} className={joinClass([c.label, classNameLabel])}>
					{label}
				</label>
			)}
			{_?.type === 'textarea' ? <textarea id={`${id}`} {..._} className={joinClass([_?.className, typeInput])} /> : <input id={`${id}`} type="text" {..._} className={joinClass([_?.className, typeInput])} />}
		</div>
	);
};
