import React from 'react';

// CSS
import c from './Loading.module.scss';

// Img
import imgLoading from './loading-dots.svg';
import imgLoadingAlt from './loading.svg';

// Cpnf
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { joinClass } from '@func/cpnt';

export const Loading = (props) => {
	const {
		className = c.icon, //
		color = 'var(--color-primary)',
		size = 'x3',
		abs,
		bg = 'var(--color-white-alpha-4)',
		show,
		alt
	} = props;

	if (!show) return null;

	return (
		<div className={joinClass([c.container, abs ? c['abs-container'] : ''])} style={{ backgroundColor: bg }}>
			<Icon img={alt ? imgLoadingAlt : imgLoading} color={color} className={`${className} ${size} ${abs ? c['abs'] : ''}`} />
		</div>
	);
};
