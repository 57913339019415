import React, { useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// CSS
import c from './Home.module.scss';

// Func
import { api } from '@api/api';
import { defaultState, msgs } from '@func/request';

// Cpnt
import { Loading } from '@cpnt/Loading/Loading';
import { joinClass } from '@func/cpnt';
import { UserItem } from '@cpnt/UserActions/UserItem/UserItem';

export const Home = () => {
	const [loading, setLoading] = useState(false);
	const [stateUsers, setStateUsers] = useState(defaultState());
	const { t } = useTranslation();

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setLoading(true);
		const req = await api.users.get();
		if (req.status === 200) {
			setStateUsers({
				...stateUsers,
				...req,
			});
		} else {
			msgs([req]);
		}
		setLoading(false);
	};

	return (
		<div className={joinClass([c.container])}>
			<div className="base">
				{loading ? (
					<div className={joinClass([c.loading])}>
						<Loading show />
					</div>
				) : stateUsers.status === 200 ? (
					<div className={joinClass([c.loop])}>
						{stateUsers.data.map((user, key) => {
							return (
								<UserItem //
									key={key}
									profileImg={user.profile_img}
									name={user.name}
									link={`/user/${user.user_name}`}
								/>
							);
						})}
					</div>
				) : (
					<div>{t('No users found')}</div>
				)}
			</div>
		</div>
	);
};
