// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VRGxc{min-height:100vh}.VRGxc .jd2Tb{min-height:100vh;display:flex;flex-direction:column}.VRGxc .zvWpW{width:100%;box-sizing:border-box;padding:var(--gap-content)}@media(max-width: 680px){.VRGxc .zvWpW{padding:var(--gap)}}`, "",{"version":3,"sources":["webpack://./src/roles/Layouts/LayoutLogged/LayoutLogged.module.scss"],"names":[],"mappings":"AAAA,OACC,gBAAA,CAEA,cACC,gBAAA,CACA,YAAA,CACA,qBAAA,CAGD,cACC,UAAA,CACA,qBAAA,CACA,0BAAA,CAEA,yBALD,cAME,kBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VRGxc`,
	"wrap": `jd2Tb`,
	"content": `zvWpW`
};
export default ___CSS_LOADER_EXPORT___;
