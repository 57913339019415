// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mTFHS{position:sticky;top:0;z-index:997;background-color:var(--color-white);box-shadow:var(--box-shadow);height:var(--topbar-height);display:flex;align-items:center}.mTFHS .ulOIV{justify-content:center;align-items:center;display:grid;grid-template-columns:minmax(60px, 60px) 1fr minmax(60px, 60px);gap:var(--gap);align-items:center}.mTFHS .ulOIV .nOnpp{font-size:24px}.mTFHS .ulOIV .WZ3Qq{cursor:pointer}.mTFHS .ulOIV .SRyDc{text-decoration:none}.mTFHS .ulOIV .nOnpp,.mTFHS .ulOIV .kW4BY{display:flex;align-items:center;justify-content:center}@media(max-width: 680px){.mTFHS .ulOIV .AQj0q{width:132px}}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,OACC,eAAA,CACA,KAAA,CACA,WAAA,CACA,mCAAA,CACA,4BAAA,CACA,2BAAA,CACA,YAAA,CACA,kBAAA,CAEA,cACC,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,+DAAA,CACA,cAAA,CACA,kBAAA,CAEA,qBACC,cAAA,CAGD,qBACC,cAAA,CAGD,qBACC,oBAAA,CAGD,0CAEC,YAAA,CACA,kBAAA,CACA,sBAAA,CAGD,yBACC,qBACC,WAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mTFHS`,
	"wrap": `ulOIV`,
	"icon": `nOnpp`,
	"btn-resp": `WZ3Qq`,
	"link-logo": `SRyDc`,
	"logo-box": `kW4BY`,
	"logo": `AQj0q`
};
export default ___CSS_LOADER_EXPORT___;
