// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@img/default/bg-header.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ja9Kr{background:linear-gradient(to bottom, transparent, #fff),var(--color-white) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center -45px;background-size:90vw;display:flex;flex-direction:column;align-items:center;gap:var(--gap-content);padding:7vw var(--padding-base) var(--gap-big)}.LdUTw{display:grid;grid-template-columns:repeat(5, 1fr);justify-content:center;flex-wrap:wrap;gap:var(--gap-big)}@media(max-width: 1200px){.LdUTw{grid-template-columns:repeat(4, 1fr)}}@media(max-width: 980px){.LdUTw{grid-template-columns:repeat(3, 1fr);gap:var(--gap-large)}}@media(max-width: 680px){.LdUTw{grid-template-columns:repeat(2, 1fr);gap:var(--gap-item)}}@media(max-width: 480px){.LdUTw{grid-template-columns:repeat(1, 1fr)}}.uqg0y{display:flex;align-items:center;justify-content:center;padding:var(--gap-big)}`, "",{"version":3,"sources":["webpack://./src/pages/notLogged/Home/Home.module.scss"],"names":[],"mappings":"AAAA,OACC,0IAAA,CACA,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,8CAAA,CAED,OACC,YAAA,CACA,oCAAA,CACA,sBAAA,CACA,cAAA,CACA,kBAAA,CAEA,0BAPD,OAQE,oCAAA,CAAA,CAGD,yBAXD,OAYE,oCAAA,CACA,oBAAA,CAAA,CAGD,yBAhBD,OAiBE,oCAAA,CACA,mBAAA,CAAA,CAGD,yBArBD,OAsBE,oCAAA,CAAA,CAGF,OACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ja9Kr`,
	"loop": `LdUTw`,
	"loading": `uqg0y`
};
export default ___CSS_LOADER_EXPORT___;
