// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes HtqNl{from{left:-200%}to{left:200%}}.rNujx{display:inline-flex;align-items:center;flex-direction:column;gap:var(--gap-item)}.rNujx.mjxiD .bzLXr{background-color:var(--color-white);box-shadow:var(--box-shadow);border-radius:50%}.rNujx .bzLXr{font-size:4em;padding:.9em}.rNujx .tkFjr{font-size:1.2em;font-weight:600;margin:0}.rNujx .Vwtvf{font-size:1em;font-weight:400}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Info/Info.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,mBAAA,CACA,kBAAA,CACA,qBAAA,CACA,mBAAA,CAGC,oBACC,mCAAA,CACA,4BAAA,CACA,iBAAA,CAIF,cACC,aAAA,CACA,YAAA,CAMD,cACC,eAAA,CACA,eAAA,CACA,QAAA,CAGD,cACC,aAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `rNujx`,
	"default": `mjxiD`,
	"icon": `bzLXr`,
	"title": `tkFjr`,
	"desc": `Vwtvf`,
	"animationPlaceholderLoading": `HtqNl`
};
export default ___CSS_LOADER_EXPORT___;
