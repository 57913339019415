// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tnm9O .Q9qUb .rWu6J{font-size:14px;font-weight:600}@keyframes sXtbm{from{left:-200%}to{left:200%}}.tnm9O{overflow:hidden}.tnm9O .Q9qUb{display:flex;gap:var(--gap);justify-content:space-between;cursor:pointer;padding:var(--gap) 0}.tnm9O .Q9qUb .rWu6J{margin:0}.tnm9O .Q9qUb .NQ0rD{font-size:10px}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_texts.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Accordeon/Accordeon.module.scss"],"names":[],"mappings":"AAIA,qBACC,cAAA,CACA,eAAA,CCuBA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,eAAA,CAEA,cACC,YAAA,CACA,cAAA,CACA,6BAAA,CACA,cAAA,CACA,oBAAA,CAEA,qBAEC,QAAA,CAGD,qBACC,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `tnm9O`,
	"box": `Q9qUb`,
	"title": `rWu6J`,
	"icon": `NQ0rD`,
	"animationPlaceholderLoading": `sXtbm`
};
export default ___CSS_LOADER_EXPORT___;
