// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes XlhyV{from{left:-200%}to{left:200%}}.hJYSN{font-size:8px;background-color:var(--color-green-white);border-radius:var(--border-radius);padding:3px 5px}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Icons/IconVerified/IconVerified.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,aAAA,CACA,yCAAA,CACA,kCAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-box": `hJYSN`,
	"animationPlaceholderLoading": `XlhyV`
};
export default ___CSS_LOADER_EXPORT___;
