import React from 'react';

// CSS
import c from './LayoutChef.module.scss';

// Func
import { joinClass } from '@func/cpnt';

// Cpnt
import { Header } from '@cpnt/Header/Header';
import { Sidebar } from '@cpnt/Sidebar/Sidebar';

export const LayoutChef = (props) => {
	const { ...rest } = props;

	const Cpnt = props.component;

	return (
		<div className={joinClass([c.container])}>
			{/* <Sidebar {...props} /> */}
			<div className={joinClass([c.wrap])}>
				<Header />
				<div className={joinClass([c.inner])}>
					<div className={joinClass([c.content])}>
						<Cpnt {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};
