// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yN2y1 .L37zO{font-size:30px;line-height:1}@keyframes F0SB6{from{left:-200%}to{left:200%}}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_texts.scss","webpack://./src/assets/scss/placeholders/_utils.scss"],"names":[],"mappings":"AAAA,cACC,cAAA,CACA,aAAA,CC2BA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `yN2y1`,
	"title": `L37zO`,
	"animationPlaceholderLoading": `F0SB6`
};
export default ___CSS_LOADER_EXPORT___;
