import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Langs
import { EN_US } from './en-us';
import { PT_BR } from './pt-br';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: EN_US
		},
		pt: {
			translation: PT_BR
		}
	},
	lng: 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
