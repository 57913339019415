// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@img/default/bg-header.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes sfScY{from{left:-200%}to{left:200%}}.Ce3NS{display:flex;width:100vw;min-height:100vh;justify-content:center;align-items:center;background:linear-gradient(to bottom, transparent, var(--color-white)),var(--color-white) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center -45px;background-size:100%}.Ce3NS .NP3ZE{margin:auto;padding:var(--gap)}.Ce3NS .g6AoS{display:grid;width:980px;grid-template-columns:repeat(2, minmax(0, 1fr));box-shadow:var(--box-shadow-box);border-radius:var(--border-radius);overflow:hidden;border:1px solid var(--color-border)}.Ce3NS .N8zBO{position:relative;background:linear-gradient(to bottom right, var(--color-primary-light), #fff 30% 70%, var(--color-primary-light));background-size:cover;display:flex;justify-content:center;align-items:center;border-right:1px solid var(--color-border)}.Ce3NS .N8zBO .Cmo36{position:relative;z-index:1;width:250px}.Ce3NS .N8zBO .Cmo36 img{display:block;width:100%}.Ce3NS .Uo4ci{background-color:#fff;padding:80px}@media(max-width: 1020px){.Ce3NS .g6AoS{display:flex;flex-wrap:wrap;flex-direction:column;width:auto}.Ce3NS .N8zBO{padding:var(--gap-content)}.Ce3NS .Uo4ci{padding:30px}}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/roles/Layouts/LayoutUserActions/LayoutUserActions.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,YAAA,CACA,WAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CACA,wJAAA,CACA,oBAAA,CAEA,cACC,WAAA,CACA,kBAAA,CAGD,cACC,YAAA,CACA,WAAA,CACA,+CAAA,CACA,gCAAA,CACA,kCAAA,CACA,eAAA,CACA,oCAAA,CAGD,cACC,iBAAA,CACA,iHAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,0CAAA,CAEA,qBACC,iBAAA,CACA,SAAA,CACA,WAAA,CAEA,yBACC,aAAA,CACA,UAAA,CAIH,cACC,qBAAA,CACA,YAAA,CAGD,0BACC,cACC,YAAA,CACA,cAAA,CACA,qBAAA,CACA,UAAA,CAED,cACC,0BAAA,CAED,cACC,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ce3NS`,
	"box-center": `NP3ZE`,
	"wrap": `g6AoS`,
	"left": `N8zBO`,
	"logo-wrap": `Cmo36`,
	"right": `Uo4ci`,
	"animationPlaceholderLoading": `sfScY`
};
export default ___CSS_LOADER_EXPORT___;
