// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes aEHnc{from{left:-200%}to{left:200%}}.ojfPm{display:flex;align-items:center;gap:var(--gap-small-alt)}.ojfPm.k8vjL{flex-direction:column}.ojfPm.k8vjL.zmKtH{flex-direction:column-reverse}.ojfPm.zmKtH{flex-direction:row-reverse}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/TextIcon/TextIcon.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,YAAA,CACA,kBAAA,CAEA,wBAAA,CAKA,aACC,qBAAA,CAEA,mBACC,6BAAA,CAIF,aACC,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ojfPm`,
	"vertical": `k8vjL`,
	"reverse": `zmKtH`,
	"animationPlaceholderLoading": `aEHnc`
};
export default ___CSS_LOADER_EXPORT___;
