// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SXnYV{background-color:var(--color-white-alpha-2);backdrop-filter:blur(5px)}@keyframes Jiq66{from{left:-200%}to{left:200%}}.SXnYV{width:1em;height:1em;border:2px solid var(--color-white);border-radius:50%;padding:.4em;display:flex;justify-content:center;align-items:center}.SXnYV .fA_y9{font-size:.8em;margin-left:.08em}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Icons/IconVideoPlay/IconVideoPlayPause.module.scss"],"names":[],"mappings":"AAkCA,OACC,2CAAA,CACA,yBAAA,CCPA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OAEC,SAAA,CACA,UAAA,CACA,mCAAA,CACA,iBAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,cACC,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SXnYV`,
	"icon": `fA_y9`,
	"animationPlaceholderLoading": `Jiq66`
};
export default ___CSS_LOADER_EXPORT___;
