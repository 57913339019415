// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes SemcH{from{left:-200%}to{left:200%}}.YYKWT .vSZVk{width:2em;height:2em}.ebfeY{position:absolute;inset:0;display:flex;justify-content:center;align-items:center;z-index:10}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Loading/Loading.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CC/BF,cACC,SAAA,CACA,UAAA,CAGF,OACC,iBAAA,CACA,OAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `YYKWT`,
	"icon": `vSZVk`,
	"abs-container": `ebfeY`,
	"animationPlaceholderLoading": `SemcH`
};
export default ___CSS_LOADER_EXPORT___;
