import React, { useEffect, useRef, useState } from 'react';

// Libs
import { animated, useSpring } from '@react-spring/web';

// CSS
import c from './Accordeon.module.scss';

// Imgs
import imgIconOpened from '@icon/icon-chev-bottom.svg';

// Componentes
import { Icon } from '@cpnt/Icons/Icon/Icon';

// Funções
import { joinClass } from '@func/cpnt';

export const Accordeon = (props) => {
	const {
		children, //
		className = '',
		classNameChildren = '',
		classNameOpened,
		classNameClosed,
		title
	} = props;

	const contentRef = useRef(null);

	const [isOpen, setIsOpen] = useState(true);
	const [contentHeight, setContentHeight] = useState(0);

	const updateContentHeight = () => {
		if (contentRef.current) {
			setContentHeight(contentRef.current.scrollHeight);
		}
	};

	useEffect(() => {
		updateContentHeight();
	}, [children]);

	useEffect(() => {
		window.addEventListener('resize', updateContentHeight);
		return () => window.removeEventListener('resize', updateContentHeight);
	}, []);

	const animation = useSpring({
		height: isOpen ? contentHeight : 0,
		config: { tension: 1000, friction: 150 }
	});

	const rotate = useSpring({
		transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
		config: { tension: 1000, friction: 150 }
	});

	const toggleAccordion = () => setIsOpen((prev) => !prev);

	return (
		<div className={joinClass([className, c.container, isOpen ? classNameOpened : classNameClosed])}>
			<div className={joinClass([c.box])} onClick={toggleAccordion}>
				<h4 className={joinClass([c.title])}>{title}</h4>
				<animated.div style={{ ...rotate, alignSelf: 'center' }}>
					<Icon img={imgIconOpened} color="var(--color-black)" style={{ ...rotate }} className={joinClass([c.icon])} />
				</animated.div>
			</div>
			<animated.div style={{ ...animation, overflow: 'hidden' }}>
				<div className={joinClass([c.children, classNameChildren])} ref={contentRef}>
					{children}
				</div>
			</animated.div>
		</div>
	);
};
