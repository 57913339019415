import { useRoutes } from 'react-router-dom';

// Layouts
import { LayoutChef } from '@role/Layouts/LayoutChef/LayoutChef';
import { LayoutRecipe } from '@role/Layouts/LayoutRecipe/LayoutRecipe';
import { LayoutBlank } from '@role/Layouts/LayoutBlank/LayoutBlank';
import { LayoutUserActions } from '@role/Layouts/LayoutUserActions/LayoutUserActions';
import { LayoutPage } from '@role/Layouts/LayoutPage/LayoutPage';

// Sidebar
import { SIDEBAR_GENERAL } from '@role/Sidebar/SidebarGeneral';

// Pages
import { RecipeCook } from '@cpnt/RecipeContainer/RecipeCook/RecipeCook';
import { RecipeShop } from '@cpnt/RecipeContainer/RecipeShop/RecipeShop';
import { Logout } from '@page/general/Logout/Logout';
import { Error404 } from '@page/notLogged/Error404/Error404';
import { Home } from '@page/notLogged/Home/Home';
import { Login } from '@page/notLogged/Login/Login';
import { Recipe } from '@page/notLogged/Recipe/Recipe';
import { User } from '@page/notLogged/User/User';

export const ROUTES_GENERAL = [
	{
		path: '/',
		element: (
			<LayoutPage
				meta={{
					title: 'CookBook',
				}}
				component={Home}
			/>
		),
	},
	{
		path: '/login',
		element: (
			<LayoutUserActions
				meta={{
					title: 'Login',
				}}
				component={Login}
			/>
		),
	},
	{
		path: '/user/:user_id',
		element: (
			<LayoutChef
				meta={{
					title: 'User',
				}}
				component={User}
				sidebar={SIDEBAR_GENERAL}
			/>
		),
	},
	{
		path: '/user/:user_id/recipe/:recipe_id',
		element: (
			<LayoutRecipe
				meta={{
					title: 'Recipe',
				}}
				component={Recipe}
			/>
		),
		children: [
			{
				index: true,
				element: <RecipeCook />,
			},
			{
				path: 'cook',
				element: <RecipeCook />,
			},
			{
				path: 'shop',
				element: <RecipeShop />,
			},
		],
	},
	{
		path: '/logout',
		element: (
			<Logout
				meta={{
					title: 'Loggout, Wait...',
				}}
			/>
		),
	},
	{
		path: '*',
		element: (
			<LayoutUserActions
				meta={{
					title: 'Página não encontrada...',
				}}
				component={Error404}
			/>
		),
	},
];

function ScreensGeneral() {
	const routesGeneral = useRoutes(ROUTES_GENERAL);
	return routesGeneral;
}

export { ScreensGeneral };
