// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n4_Av .nX2as{box-sizing:border-box;width:100%;padding:var(--pading-input-v) var(--pading-input-h);border-radius:var(--border-radius);outline:none;transition:var(--transition);font-family:inherit;font-size:1em}.n4_Av .nX2as{display:block}.n4_Av .PdqM1.nX2as{border:1px solid var(--color-primary-alpha-7);background-color:var(--color-white)}.n4_Av .PdqM1.nX2as:hover,.n4_Av .PdqM1.nX2as:focus{border-color:var(--color-primary-alpha-9);box-shadow:var(--box-shadow)}.n4_Av .LXXYm.nX2as{border:1px solid var(--color-border);background-color:var(--color-white)}.n4_Av .LXXYm.nX2as:hover,.n4_Av .LXXYm.nX2as:focus{border-color:var(--color-primary-alpha-5);box-shadow:0 3px 15px var(--color-primary-alpha-3);background-color:var(--color-white)}.n4_Av label{color:var(--color-text-2);margin-bottom:var(--gap-min);font-size:var(--fs-label)}@keyframes PQVzO{from{left:-200%}to{left:200%}}.n4_Av{position:relative}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_inputs.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Select/Select.module.scss"],"names":[],"mappings":"AAAA,cACC,qBAAA,CACA,UAAA,CACA,mDAAA,CACA,kCAAA,CACA,YAAA,CACA,4BAAA,CACA,mBAAA,CACA,aAAA,CAGD,cAEC,aAAA,CAEA,oBACC,6CAAA,CACA,mCAAA,CAEA,oDAEC,yCAAA,CACA,4BAAA,CAIF,oBACC,oCAAA,CACA,mCAAA,CAEA,oDAEC,yCAAA,CACA,kDAAA,CACA,mCAAA,CAKH,aACC,yBAAA,CACA,4BAAA,CACA,yBAAA,CCbA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `n4_Av`,
	"select": `nX2as`,
	"default": `PdqM1`,
	"alt": `LXXYm`,
	"animationPlaceholderLoading": `PQVzO`
};
export default ___CSS_LOADER_EXPORT___;
