import React, { useContext, useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './Recipe.module.scss';

// Img
import imgPlaceholder from '@img/default/placeholder.webp';

// Func
import { api } from '@api/api';
import { RecipeContainer } from '@cpnt/RecipeContainer/RecipeContainer';
import { Video } from '@cpnt/Video/Video';
import { joinClass, msg } from '@func/cpnt';
import { defaultState } from '@func/request';

export const Recipe = () => {
	const [store, setStore] = useContext(StoreContext);

	const { recipe_id, user_id } = useParams();

	const [loadingGetUser, setLoadingGetUser] = useState(false);
	const [loadingGetRecipe, setLoadingGetRecipe] = useState(false);
	const [loadingGetCart, setLoadingGetCart] = useState(false);

	const [stateUser, setStateUser] = useState(defaultState());
	const [stateRecipe, setStateRecipe] = useState(defaultState());
	const [stateCart, setStateCart] = useState(defaultState());

	const { t } = useTranslation();

	useEffect(() => {
		getUser();
		getRecipe();
		getCart();
	}, []);

	const getUser = async () => {
		if (loadingGetUser) {
			msg.warning(t('Please wait...'));
			return;
		}

		setLoadingGetUser(true);

		const req = await api.users.id.get({ userId: user_id });

		setStateUser({
			...stateUser,
			...req,
		});

		setLoadingGetUser(false);
	};

	const getRecipe = async () => {
		if (loadingGetRecipe) {
			msg.warning(t('Please wait...'));
			return;
		}

		setLoadingGetRecipe(true);

		const req = await api.users.id.recipes.id.get({ userId: user_id, recipeId: recipe_id });

		setStateRecipe({
			...stateRecipe,
			...req,
		});

		setLoadingGetRecipe(false);
	};

	const getCart = async () => {
		if (loadingGetCart) {
			msg.warning(t('Please wait...'));
			return;
		}

		setLoadingGetCart(true);

		const req = await api.users.id.recipes.carts.get({ userId: user_id, recipeId: recipe_id });

		setStateCart({
			...stateCart,
			...req,
		});

		setLoadingGetCart(false);
	};

	const toggleRecipeContainer = () => {
		setStore({
			...store,
			config: {
				...store.config,
				showResponsiveRecipeActions: !store.config.showResponsiveRecipeActions,
			},
		});
	};

	return (
		<div className={joinClass([c.container])}>
			<div className={joinClass([c.left])} style={{ backgroundImage: `url(${loadingGetRecipe || stateRecipe.status !== 200 ? imgPlaceholder : stateRecipe?.data?.thumbnail})` }}>
				<Video
					loading={loadingGetRecipe}
					altControlsPosition
					backLink={`/user/${user_id}`}
					onReady={(player) => {}}
					user={stateUser}
					recipe={stateRecipe}
					onClickAction={toggleRecipeContainer}
					options={{
						techOrder: ['youtube'],
						controls: false,
						muted: false,
						autoplay: true,
						loop: false,
						preload: 'auto',
						sources: [
							{
								src: `https://www.youtube.com/watch?v=${recipe_id}`,
								type: 'video/youtube',
							},
						],
						youtube: {
							modestbranding: 1, // Minimiza o logo do YouTube
							rel: 0, // Impede vídeos relacionados de aparecerem ao final
							showinfo: 0, // Remove título e outros detalhes (ainda respeitado por alguns vídeos)
							iv_load_policy: 3, // Desativa anotações
							fs: 1, // Desativa o botão de tela cheia
							disablekb: 0, // Desativa os controles de teclado
							controls: 0, // Remove os controles padrão do YouTube
						},
						html5: {
							nativeAudioTracks: true,
							nativeVideoTracks: true,
							hls: {
								overrideNative: true,
							},
						},
					}}
				/>
				<div className={joinClass([c.overlay])}></div>
			</div>
			<div className={joinClass([c.right, store?.config?.showResponsiveRecipeActions ? c['right-active'] : ''])}>
				<RecipeContainer
					{...{
						// User
						getUser,
						stateUser,
						loadingGetUser,

						// Cook
						getRecipe,
						stateRecipe,
						loadingGetRecipe,

						// Cart
						getCart,
						stateCart,
						loadingGetCart,
					}}
				/>
			</div>
		</div>
	);
};
