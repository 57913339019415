// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes O5g36{from{left:-200%}to{left:200%}}.PkeUJ .Um5ov{display:flex;flex-direction:column;gap:var(--gap-content)}.PkeUJ .Um5ov .IhYfr{display:grid;grid-template-columns:repeat(3, 1fr);grid-template-rows:auto auto;gap:var(--gap);align-items:flex-end}@media(max-width: 680px){.PkeUJ .Um5ov .IhYfr{grid-template-columns:repeat(1, 1fr)}}.PkeUJ .Um5ov .uAYtE{margin-left:auto}.PkeUJ .FgVG4{display:flex;flex-direction:column;gap:var(--gap-large)}.PkeUJ .FgVG4 .goDTQ{display:inline-flex;align-items:center;gap:var(--gap)}.PkeUJ .kGMN7{margin-top:var(--gap-content);display:grid;grid-template-columns:repeat(5, 1fr);justify-content:center;flex-wrap:wrap;gap:var(--gap-big)}@media(max-width: 1200px){.PkeUJ .kGMN7{grid-template-columns:repeat(4, 1fr)}}@media(max-width: 980px){.PkeUJ .kGMN7{grid-template-columns:repeat(3, 1fr);gap:var(--gap-large)}}@media(max-width: 680px){.PkeUJ .kGMN7{grid-template-columns:repeat(2, 1fr);gap:var(--gap-item)}}@media(max-width: 480px){.PkeUJ .kGMN7{grid-template-columns:repeat(1, 1fr)}}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/pages/logged/ManageUsers/ManageUsers.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CC/BF,cACC,YAAA,CACA,qBAAA,CACA,sBAAA,CAEA,qBACC,YAAA,CACA,oCAAA,CACA,4BAAA,CACA,cAAA,CACA,oBAAA,CAEA,yBAPD,qBAQE,oCAAA,CAAA,CAIF,qBACC,gBAAA,CAIF,cACC,YAAA,CACA,qBAAA,CACA,oBAAA,CAEA,qBACC,mBAAA,CACA,kBAAA,CACA,cAAA,CAIF,cACC,6BAAA,CACA,YAAA,CACA,oCAAA,CACA,sBAAA,CACA,cAAA,CACA,kBAAA,CAEA,0BARD,cASE,oCAAA,CAAA,CAGD,yBAZD,cAaE,oCAAA,CACA,oBAAA,CAAA,CAGD,yBAjBD,cAkBE,oCAAA,CACA,mBAAA,CAAA,CAGD,yBAtBD,cAuBE,oCAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PkeUJ`,
	"form": `Um5ov`,
	"inner": `IhYfr`,
	"btn-wrap": `uAYtE`,
	"wrap": `FgVG4`,
	"title-box": `goDTQ`,
	"loop": `kGMN7`,
	"animationPlaceholderLoading": `O5g36`
};
export default ___CSS_LOADER_EXPORT___;
