import React, { useContext, useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './ManageUsers.module.scss';

// Func
import { api } from '@api/api';
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { Input } from '@cpnt/Inputs/Input/Input';
import { Loading } from '@cpnt/Loading/Loading';
import { joinClass, msg } from '@func/cpnt';
import { defaultState, msgs } from '@func/request';
import { invalidFields } from '@func/validate';
import { UserItem } from '@cpnt/UserActions/UserItem/UserItem';

export const ManageUsers = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const { className } = props;
	const { t } = useTranslation();

	const DEFAULT_USER_STATE = {
		name: '',
		user_name: '',
		instagram: '',
		tik_tok: '',
		you_tube: '',
		bio: 'Shop all my recipes on my Cookbook!',
	};

	const [loadingInsertUser, setLoadingInsertUser] = useState(false);
	const [loadingGetUsers, setLoadingGetUsers] = useState(false);
	const [stateUser, setStateUser] = useState(DEFAULT_USER_STATE);
	const [stateUsers, setStateUsers] = useState(defaultState());

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setLoadingGetUsers(true);
		const req = await api.users.get();
		if (req.status === 200) {
			setStateUsers({
				...stateUsers,
				...req,
			});
		} else {
			msgs([req]);
		}
		setLoadingGetUsers(false);
	};

	const insetUser = async () => {
		if (loadingInsertUser) {
			msg.warning(t('Please wait...'));
			return;
		}

		const fields = {
			name: stateUser.name,
			user_name: stateUser.user_name,
		};

		if (invalidFields({ fields })) {
			msg.error(t('Please fill in all fields'));
			return;
		}

		setLoadingInsertUser(true);

		const req = await api.users.post({
			...fields,
			instagram: stateUser.instagram,
			tik_tok: stateUser.tik_tok,
			you_tube: stateUser.you_tube,
			bio: stateUser.bio,
		});

		if (req.status === 201) {
			msg.success(t('User added successfully'));
			setStateUser(DEFAULT_USER_STATE);
			getUsers();
		} else {
			msgs([req]);
		}

		setLoadingInsertUser(false);
	};

	return (
		<div className={joinClass([c.container])}>
			<div className={joinClass(['base'])}>
				<div className={joinClass(['base-content'])}>
					<div className={joinClass([c.wrap])}>
						<div>
							<h3>{t('Add new user')}</h3>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									insetUser();
								}}
								className={joinClass([c.form])}
							>
								<div className={joinClass([c.inner])}>
									<Input //
										_={{
											value: stateUser.name,
											onChange: (e) => setStateUser({ ...stateUser, name: e.target.value }),
										}}
										label={t('Name')}
									/>
									<Input //
										_={{
											value: stateUser.user_name,
											onChange: (e) => setStateUser({ ...stateUser, user_name: e.target.value }),
										}}
										label={t('Username')}
									/>
									<Input //
										_={{
											value: stateUser.instagram,
											onChange: (e) => setStateUser({ ...stateUser, instagram: e.target.value }),
										}}
										label={t('Instagram')}
									/>
									<Input //
										_={{
											value: stateUser.tik_tok,
											onChange: (e) => setStateUser({ ...stateUser, tik_tok: e.target.value }),
										}}
										label={t('Tik Tok')}
									/>
									<Input //
										_={{
											value: stateUser.you_tube,
											onChange: (e) => setStateUser({ ...stateUser, you_tube: e.target.value }),
										}}
										label={t('YouTube')}
									/>
									<Input //
										_={{
											value: stateUser.bio,
											onChange: (e) => setStateUser({ ...stateUser, bio: e.target.value }),
										}}
										label={t('Bio')}
									/>
								</div>
								<Btn
									_={{
										value: t('Save new user'),
									}}
									className={joinClass([c['btn-wrap']])}
									loading={loadingInsertUser}
								/>
							</form>
						</div>
						<div>
							<div className={joinClass([c.container])}>
								<div className={joinClass([c['title-box']])}>
									<h3>{t('All users')}</h3>
									<Btn
										_={{
											value: t('Reload'),
											onClick: getUsers,
										}}
										size="small"
										loading={loadingGetUsers}
									/>
								</div>
								{loadingGetUsers ? (
									<Loading show />
								) : stateUsers.status === 200 ? (
									<div className={joinClass([c.loop])}>
										{stateUsers.data.map((user, key) => {
											return (
												<UserItem //
													key={key}
													profileImg={user.profile_img}
													name={user.name}
													link={`/user/${user.user_name}`}
												/>
											);
										})}
									</div>
								) : (
									<div>{t('No users found')}</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
