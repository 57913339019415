// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SFjai{display:flex;align-items:center;justify-content:center;gap:var(--gap-inputs)}.nyAH0{display:flex;align-items:center;justify-content:space-between;gap:var(--gap-inputs)}.nyAH0 .qEBI0{font-size:14px;font-weight:600}@keyframes Cr4Q9{from{left:-200%}to{left:200%}}.SFjai{background-color:var(--color-white);align-items:flex-end;gap:var(--gap-large);font-size:var(--fs-title-small);font-weight:600;top:0;position:sticky;padding-top:var(--gap);z-index:10}.SFjai>*{margin-bottom:-1px}.SFjai::after{content:"";border-bottom:3px solid var(--color-border-alt);border-radius:var(--border-radius-min);position:absolute;width:100%;bottom:0}.SFjai .M0USb{font-size:28px}.SFjai .cXcU0{font-size:24px}.SFjai .QP0AK{font-size:24px}@media(max-width: 1200px){.SFjai{padding-left:var(--gap);padding-right:var(--gap);gap:var(--gap-small-alt);justify-content:space-around}}.nyAH0{position:sticky;top:0;z-index:999;background-color:var(--color-white);border-bottom:1px solid var(--color-border);padding:var(--gap-item) var(--gap-inputs) var(--gap-item)}.nyAH0 .qEBI0{margin:0}.nyAH0 .wB8qs{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_texts.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/RecipeContainer/RecipeContainer.module.scss"],"names":[],"mappings":"AAsBA,OACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CAED,OACC,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,qBAAA,CC5BD,cACC,cAAA,CACA,eAAA,CCuBA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,mCAAA,CAEA,oBAAA,CACA,oBAAA,CACA,+BAAA,CACA,eAAA,CAEA,KAAA,CACA,eAAA,CACA,sBAAA,CACA,UAAA,CAEA,SACC,kBAAA,CAGD,cACC,UAAA,CACA,+CAAA,CACA,sCAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA,CAGD,cACC,cAAA,CAGD,cACC,cAAA,CAGD,cACC,cAAA,CAGD,0BAtCD,OAuCE,uBAAA,CACA,wBAAA,CACA,wBAAA,CACA,4BAAA,CAAA,CAIF,OAEC,eAAA,CACA,KAAA,CACA,WAAA,CACA,mCAAA,CACA,2CAAA,CACA,yDAAA,CAEA,cAEC,QAAA,CAGD,cACC,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `SFjai`,
	"header-mobile": `nyAH0`,
	"title": `qEBI0`,
	"icon-cook": `M0USb`,
	"icon-adapt": `cXcU0`,
	"icon-shop": `QP0AK`,
	"x": `wB8qs`,
	"animationPlaceholderLoading": `Cr4Q9`
};
export default ___CSS_LOADER_EXPORT___;
