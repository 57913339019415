import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';

// CSS
import c from './RecipeHead.module.scss';

// Img
import imgIconCheck from '@icon/icon-check.svg';

// Components
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { Img } from '@cpnt/Imgs/Img/Img';

// Func
import { joinClass } from '@func/cpnt';
import { IconVerified } from '@cpnt/Icons/IconVerified/IconVerified';

export const RecipeHead = (props) => {
	const { profile_img, name, title } = props;
	const { t } = useTranslation();

	return (
		<div className={joinClass([c.header])}>
			<div className={joinClass([c['user-info']])}>
				<Img src={profile_img} className={joinClass([c.img])} />
				<div className={joinClass([c['name-box']])}>
					<div className={joinClass([c.name])}>{name || t('Unable to load user')}</div>
					{name && <IconVerified />}
				</div>
			</div>
			<h3 className={joinClass([c.title])}>{title}</h3>
		</div>
	);
};
