import React, { useContext } from 'react';

// Libs
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './Header.module.scss';

// Imgs
import imgLogo from '@img/default/logo-cookbook.svg';
import imgIconBars from '@icon/icon-hamburguer.svg';

// Func
import { joinClass, toggleSidebar } from '@func/cpnt';

// Cpnt
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const Header = ({ hideMenu }) => {
	const [store, setStore] = useContext(StoreContext);

	return (
		<div className={joinClass([c.container])}>
			<div className="base">
				<div className={joinClass([c.wrap])}>
					<div className={joinClass([c.icon, c['btn-resp']])} onClick={() => toggleSidebar({ store, setStore })}>
						{!hideMenu ? <Icon img={imgIconBars} className={joinClass([c.icon])} /> : null}
					</div>
					<div className={joinClass([c['logo-box']])}>
						<Link to="/" className={joinClass([c['link-logo']])}>
							<img src={imgLogo} alt="logo" className={joinClass([c.logo])} />
						</Link>
					</div>
					<div />
				</div>
			</div>
		</div>
	);
};
