import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// CSS
import c from './RecipeCook.module.scss';

// Func
import { joinClass } from '@func/cpnt';

// Components
import { Accordeon } from '@cpnt/Accordeon/Accordeon';
import { RecipeCookItem } from './RecipeCookItem/RecipeCookItem';

export const RecipeCook = () => {
	const [{ stateRecipe, loadingGetRecipe }] = useOutletContext();
	const { t } = useTranslation();

	return (
		<div className={joinClass([c.container])}>
			<Accordeon title={t('Ingredients')} classNameClosed={joinClass([c.accordeon])}>
				<div className={joinClass([c.wrapper])}>
					{loadingGetRecipe ? (
						<>
							<RecipeCookItem placeholder />
							<RecipeCookItem placeholder />
						</>
					) : stateRecipe.status === 200 ? (
						stateRecipe?.data?.ingredients?.map((val, key) => {
							return (
								<RecipeCookItem //
									key={key}
									title={val.name}
									desc={`${val.quantity} ${val.unit}`}
								/>
							);
						})
					) : (
						t('Unable to load ingredients')
					)}
				</div>
			</Accordeon>

			<Accordeon title={t('Preparation Method')} classNameClosed={joinClass([c.accordeon])}>
				<div className={joinClass([c.wrapper])}>
					{loadingGetRecipe ? (
						<>
							<RecipeCookItem placeholder />
							<RecipeCookItem placeholder />
						</>
					) : stateRecipe.status === 200 ? (
						stateRecipe?.data?.steps?.map((val, key) => {
							return (
								<RecipeCookItem //
									key={key}
									title={`${t('Step ')} ${key + 1}`}
									desc={val}
								/>
							);
						})
					) : (
						t('Unable to load preparation method')
					)}
				</div>
			</Accordeon>
		</div>
	);
};
