const SIDEBAR_GENERAL = {
	menu: [
		{
			title: false,
			itens: [
				// {
				// 	title: 'About',
				// 	icon: 'icon-user.svg'
				// },
				{
					title: 'Recipes',
					icon: 'icon-cam.svg',
				},
			],
		},
	],
};

export { SIDEBAR_GENERAL };
