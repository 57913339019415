import api from 'axios';
import { config, validateReturn } from '@api/axios';

const apiUsers = {
	post: async (data) => {
		return await api
			.post(`/users`, data, config())
			.then((ret) => {
				return validateReturn(ret);
			})
			.catch((ret) => {
				return validateReturn(ret);
			});
	},
	get: async () => {
		return await api
			.get(`/users`, config())
			.then((ret) => {
				return validateReturn(ret);
			})
			.catch((ret) => {
				return validateReturn(ret);
			});
	},
	id: {
		get: async ({ userId }) => {
			return await api
				.get(`/users/${userId}`, config())
				.then((ret) => {
					return validateReturn(ret);
				})
				.catch((ret) => {
					return validateReturn(ret);
				});
		},
		recipes: {
			get: async ({ userId }) => {
				return await api
					.get(`/users/${userId}/recipes`, config())
					.then((ret) => {
						return validateReturn(ret);
					})
					.catch((ret) => {
						return validateReturn(ret);
					});
			},
			carts: {
				get: async ({ userId, recipeId }) => {
					return await api
						.get(`/users/${userId}/recipes/${recipeId}/carts`, config())
						.then((ret) => {
							return validateReturn(ret);
						})
						.catch((ret) => {
							return validateReturn(ret);
						});
				},
			},
			id: {
				get: async ({ userId, recipeId }) => {
					return await api
						.get(`/users/${userId}/recipes/${recipeId}`, config())
						.then((ret) => {
							return validateReturn(ret);
						})
						.catch((ret) => {
							return validateReturn(ret);
						});
				},
				ingredients: {
					get: async ({ userId, recipeId }) => {
						return await api
							.get(`/users/${userId}/${recipeId}/ingredients`, config())
							.then((ret) => {
								return validateReturn(ret);
							})
							.catch((ret) => {
								return validateReturn(ret);
							});
					},
				},
			},
		},
	},
};

export { apiUsers };
