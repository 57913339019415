import React, { Fragment, useContext } from 'react';

// Libs
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';
import { useTranslation } from 'react-i18next';

// CSS
import c from './SidebarLogged.module.scss';

// Func
import { hideSidebar, joinClass } from '@func/cpnt';

// Imgs
import imgLogo from '@img/default/logo-cookbook.svg';

// Cpnt
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';

export const SidebarLogged = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const {
		className = '', //
		sidebar,
	} = props;

	return (
		<>
			<div className={joinClass([c.sidebar, className, store?.config?.hideSidebar ? c['sidebar-hide'] : c['hidebar-show']])}>
				<div className={joinClass([c.inner])}>
					<div className={joinClass([c.top])}>
						<div className={joinClass([c['user-info']])}>
							<img src={imgLogo} alt="cook-book" className={joinClass([c.logo])} />
						</div>
						<div>
							{sidebar?.menu.map((val, key) => {
								return (
									<Fragment key={key}>
										{val.title && <h3 className={joinClass([c.title])}>{val.title}</h3>}
										<ul className={joinClass([c.list])}>
											{val.itens.map((val2, key2) => {
												var img = require(`@icon/${val2.icon}`);
												return (
													<li key={key2}>
														<TextIcon //
															className={joinClass([c['menu-item']])}
															text={val2.title}
															Icon={{ img: img, color: false, className: c.icon }}
															onClick={() => hideSidebar({ store, setStore })}
															link={val2.path}
														/>
													</li>
												);
											})}
										</ul>
									</Fragment>
								);
							})}
						</div>
					</div>
					<div className={joinClass([c.bottom])}>
						<Btn
							_={{
								value: t('Logout'),
								onClick: () => navigate('/logout'),
							}}
							type="white"
							wide
						/>
					</div>
				</div>
			</div>
			<div className={joinClass([c.overlay, store?.config?.hideSidebar ? c['overlay-hide'] : ''])} onClick={() => hideSidebar({ store, setStore })}></div>
		</>
	);
};
