// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zZ_uX .Dedv6 .Wm9FZ{display:flex;align-items:center;justify-content:center;gap:var(--gap-small)}@keyframes BgfkV{from{left:-200%}to{left:200%}}.zZ_uX{padding:var(--gap-content) var(--gap-content) 0;background-color:var(--color-white)}.zZ_uX .Dedv6{display:flex;align-items:center;gap:var(--gap)}.zZ_uX .Dedv6 .YGkWm{border-radius:50%;width:72px;height:72px}.zZ_uX .Dedv6 .Wm9FZ{font-weight:500}.zZ_uX .qj3gp{margin:0;padding:var(--gap-content) 0 var(--gap)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/RecipeContainer/RecipeHead/RecipeHead.module.scss"],"names":[],"mappings":"AAgBA,qBACC,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oBAAA,CCSA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,+CAAA,CACA,mCAAA,CAEA,cACC,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,qBACC,iBAAA,CACA,UAAA,CACA,WAAA,CAGD,qBAEC,eAAA,CAGF,cACC,QAAA,CACA,uCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `zZ_uX`,
	"user-info": `Dedv6`,
	"name-box": `Wm9FZ`,
	"img": `YGkWm`,
	"title": `qj3gp`,
	"animationPlaceholderLoading": `BgfkV`
};
export default ___CSS_LOADER_EXPORT___;
