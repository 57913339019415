import React from 'react';

// CSS
import c from './Icon.module.scss';

// Funcs
import { joinClass } from '@func/cpnt';

export const Icon = (props) => {
	const {
		className = '', //
		img,
		color = `var(--color-1)`,
		onClick,
		wrap,
		classNameWrap,
		style,
		vector = true
	} = props;

	var styleContainer = {};

	if (color) {
		styleContainer.backgroundColor = color;
	}

	if (vector) {
		styleContainer.WebkitMaskImage = `url(${img})`;
		styleContainer.maskImage = `url(${img})`;
	} else {
		styleContainer.backgroundImage = `url(${img})`;
	}

	const Inner = () => {
		return <div className={joinClass([c.icon, className])} style={{ ...styleContainer, ...style }} {...(wrap ? {} : { onClick: onClick })} />;
	};

	if (wrap) {
		return (
			<div className={joinClass([classNameWrap])} onClick={onClick}>
				<Inner />
			</div>
		);
	}

	return <Inner />;
};
