import React from 'react';

// Libs
import { Link } from 'react-router-dom';

// CSS
import c from './UserItem.module.scss';

// Cpnt
import { joinClass } from '@func/cpnt';

// Func
import { Img } from '@cpnt/Imgs/Img/Img';

export const UserItem = (props) => {
	const {
		className = '', //
		profileImg: profile_img,
		name,
		link,
	} = props;

	return (
		<div className={joinClass([className, c.container])}>
			<Link to={link} className={joinClass([c['img-box']])}>
				<Img src={profile_img} className={joinClass([c.img])} />
			</Link>
			<Link to={link} className={joinClass([c['name']])}>
				{name}
			</Link>
		</div>
	);
};
