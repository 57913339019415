// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._gt_z{display:flex;flex-wrap:wrap;gap:var(--gap-inputs)}@keyframes L5vxh{from{left:-200%}to{left:200%}}.H7DOY{text-align:center}._gt_z{margin-top:var(--gap-large);flex-direction:column}._gt_z .mXE7E{flex:1}._gt_z .WxkZx{margin-top:var(--gap-item)}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_boxes.scss","webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/pages/notLogged/Login/Login.module.scss"],"names":[],"mappings":"AAMA,OACC,YAAA,CACA,cAAA,CACA,qBAAA,CCoBA,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,iBAAA,CAGD,OAEC,2BAAA,CACA,qBAAA,CAEA,cACC,MAAA,CAED,cACC,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `_gt_z`,
	"title": `H7DOY`,
	"input": `mXE7E`,
	"btn": `WxkZx`,
	"animationPlaceholderLoading": `L5vxh`
};
export default ___CSS_LOADER_EXPORT___;
