import React, { useContext } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './Logout.module.scss';

// Func
import { STORE_SESSION_OBJ } from '@config/consts';
import { deleteLoginSession } from '@func/auth';
import { joinClass } from '@func/cpnt';

export const Logout = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const doLogout = async () => {
		setTimeout(() => {
			deleteLoginSession();
			setStore(STORE_SESSION_OBJ);

			navigate('/login');
		}, 2000);
	};

	doLogout();

	return <h4 className={joinClass([c.text])}>{t('Logging out, please wait...')}</h4>;
};
