import React from 'react';

// CSS
import c from './IconVerified.module.scss';

// Img
import imgIconCheck from '@icon/icon-check.svg';

// Funcs
import { joinClass } from '@func/cpnt';
import { Icon } from '@cpnt/Icons/Icon/Icon';

export const IconVerified = (props) => {
	const {
		className = '' //
	} = props;

	return (
		<div className={joinClass([className, c['icon-box']])}>
			<Icon img={imgIconCheck} color="var(--color-green-light)" />
		</div>
	);
};
