const SIDEBAR_ADMIN = {
	menu: [
		{
			title: 'Admin',
			itens: [
				{
					title: 'Dashboard',
					icon: 'icon-adapt.svg',
					path: '/admin',
				},
				{
					title: 'Manage Users',
					icon: 'icon-user.svg',
					path: '/admin/users',
				},
				{
					title: 'Manage Recipes',
					icon: 'icon-cam.svg',
					path: '/admin/recipes',
				},
			],
		},
		{
			title: 'Chef',
			itens: [
				{
					title: 'Users',
					icon: 'icon-user.svg',
					path: '/users',
				},
			],
		},
	],
};

export { SIDEBAR_ADMIN };
