// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes BZxX9{from{left:-200%}to{left:200%}}.NSR4e{width:1em;height:1em;mask-position:center center;mask-size:contain;mask-repeat:no-repeat;background-position:center center;background-size:contain;background-repeat:no-repeat}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Icons/Icon/Icon.module.scss"],"names":[],"mappings":"AA6BC,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CChCH,OACC,SAAA,CACA,UAAA,CACA,2BAAA,CACA,iBAAA,CACA,qBAAA,CACA,iCAAA,CACA,uBAAA,CACA,2BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `NSR4e`,
	"animationPlaceholderLoading": `BZxX9`
};
export default ___CSS_LOADER_EXPORT___;
