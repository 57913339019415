import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// CSS
import c from './RecipeVideoItem.module.scss';

// Imgs
import imgIconAdapt from '@icon/icon-adapt.svg';
import imgIconShop from '@icon/icon-shop.svg';
import imgIconTime from '@icon/icon-time.svg';
import imgIconCook from '@img/default/logo-single.svg';

// Cpnt
import { IconVideoPlayPause } from '@cpnt/Icons/IconVideoPlay/IconVideoPlayPause';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';
import { joinClass } from '@func/cpnt';

export const RecipeVideoItem = (props) => {
	const { t } = useTranslation();

	const {
		link, //
		className = '',
		title,
		time,
		img,
		recipeId,
		userId,
		placeholder,
	} = props;

	return (
		<div className={joinClass([className, c.container])}>
			<div className={joinClass([c.wrap])}>
				{placeholder ? <div className={joinClass([c.placeholder])} /> : null}
				<Link to={link} className={joinClass([c.top])} style={{ backgroundImage: `url(${img})` }}>
					<IconVideoPlayPause className={joinClass([c['video-play']])} />
					<div className={joinClass([c['top-info']])}>
						<h3 className={joinClass([c.title])}>{title}</h3>
						<TextIcon
							text={`${time}`} //
							Icon={{ img: imgIconTime, color: 'var(--color-white)' }}
						/>
					</div>
				</Link>
				<div className={joinClass([c['bottom-info']])}>
					<div className={joinClass([c['cta']])}>
						<Link to={`/user/${userId}/recipe/${recipeId}/cook`} className={joinClass([c['cta-btn'], c['active']])}>
							<TextIcon
								Icon={{ img: imgIconCook, color: 'var(--color-white)', className: c['cta-btn-icon'] }} //
								text={t('Cook')}
								color="var(--color-white)"
								className={c['icon-wrap']}
							/>
						</Link>
						<Link to={`/user/${userId}/recipe/${recipeId}/shop`} className={joinClass([c['cta-btn'], c['active']])}>
							<TextIcon
								Icon={{ img: imgIconShop, color: 'var(--color-white)', className: c['cta-btn-icon'] }} //
								text={t('Shop')}
								color="var(--color-white)"
								className={c['icon-wrap']}
							/>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
