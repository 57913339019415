import React from 'react';

// Libs
import { useTranslation } from 'react-i18next';

// CSS
import c from './Error404.module.scss';

// Cpnt
import { joinClass } from '@func/cpnt';

export const Error404 = (props) => {
	const { t } = useTranslation();

	return (
		<div className={joinClass(['box-txt', c.container])}>
			<h1 className={joinClass([c.title])}>{t('Error 404')}</h1>
			<p>{t('Page not found...')}</p>
		</div>
	);
};
