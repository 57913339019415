import React, { useContext, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './Login.module.scss';

// Cpnt
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { Input } from '@cpnt/Inputs/Input/Input';
import { createLoginSession, isLogged } from '@func/auth';
import { joinClass, msg } from '@func/cpnt';
import { wait } from '@func/fake';

export const Login = () => {
	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const defaultFields = {
		email: '',
		password: '',
	};

	const [loading, setLoading] = useState(false);
	const [stateData, setStateData] = useState(defaultFields);

	const doLogin = async (e) => {
		e.preventDefault();

		if (loading) {
			msg.warning(t('Please wait...'));
			return false;
		}

		const data = {
			email: stateData.email,
			password: stateData.password,
		};

		setLoading(true);
		await wait(2000);

		var valid = false;

		if (data.email === 'demo@usecookbook.com' && data.password === 'demo') {
			valid = true;
		}

		if (data.email === 'test@usecookbook.com' && data.password === 'test123') {
			valid = true;
		}

		if (!valid) {
			msg.error(t('Invalid credentials'));
			setLoading(false);
			return;
		}

		createLoginSession({
			user: {
				name: 'Cook Book Demo',
				email: data.email,
			},
			token: 'Q29vayBCb29rIERlbW8=',
		});

		setStore({
			...store,
			...isLogged(),
		});

		msg.success(t('Login successfully'));

		setLoading(false);

		navigate('/admin');
	};

	return (
		<div className="box-txt">
			<h3 className={joinClass([c.title])}>{t('Login on CookBook')}</h3>

			<form onSubmit={doLogin} className={joinClass([c.form])}>
				<Input
					label="E-mail"
					className={joinClass([c.input])}
					_={{
						type: 'email',
						value: stateData.email,
						onChange: (e) => setStateData({ ...stateData, email: e.target.value }),
					}}
				/>
				<Input
					label="Password"
					className={joinClass([c.input])}
					_={{
						type: 'password',
						value: stateData.password,
						onChange: (e) => setStateData({ ...stateData, password: e.target.value }),
					}}
				/>
				<Btn
					_={{
						value: 'Login',
						type: 'submit',
						className: c.btn,
					}}
					loading={loading}
					// type="primary"
					wide
				/>
			</form>
		</div>
	);
};
