import { LOCAL_STORAGE_SESSION_KEY } from '@config/config';
import { STORE_SESSION_OBJ } from '@config/consts';

export const createLoginSession = (data = {}) => {
	var ret;
	try {
		localStorage.setItem(`@${LOCAL_STORAGE_SESSION_KEY}/token`, data.token);
		localStorage.setItem(`@${LOCAL_STORAGE_SESSION_KEY}/user`, JSON.stringify(data.user));
		ret = true;
	} catch (error) {
		ret = false;
	}
	return ret;
};

export const deleteLoginSession = () => {
	var ret;
	try {
		localStorage.removeItem(`@${LOCAL_STORAGE_SESSION_KEY}/token`);
		localStorage.removeItem(`@${LOCAL_STORAGE_SESSION_KEY}/user`);
		ret = true;
	} catch (error) {
		ret = false;
	}
	return ret;
};

export const isLogged = () => {
	var ret = false;

	try {
		var token = localStorage.getItem(`@${LOCAL_STORAGE_SESSION_KEY}/token`);
		var user = localStorage.getItem(`@${LOCAL_STORAGE_SESSION_KEY}/user`);

		if (!token || token === '' || !user || user === '') {
			return false;
		}

		try {
			user = JSON.parse(user);
		} catch (error) {
			return false;
		}

		return {
			...STORE_SESSION_OBJ,
			token: token,
			user: user
		};
	} catch (error) {
		ret = false;
	}
	return ret;
};
