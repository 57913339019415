import React, { useContext, useEffect, useState } from 'react';

// Libs
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './ManageRecipes.module.scss';

// Func
import { defaultState, msgs } from '@func/request';
import { invalidFields } from '@func/validate';
import { joinClass, msg } from '@func/cpnt';

// Cpnt
import { api } from '@api/api';
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { Img } from '@cpnt/Imgs/Img/Img';
import { Input } from '@cpnt/Inputs/Input/Input';
import { Loading } from '@cpnt/Loading/Loading';
import { Select } from '@cpnt/Select/Select';

export const ManageRecipes = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const { className } = props;
	const { t } = useTranslation();

	const DEFAULT_STATE_RECIPE = {
		user_name: '',
		recipes: [{ label: '', value: '' }],
	};

	const [loadingInsertRecipe, setLoadingInsertRecipe] = useState(false);
	const [loadingGetUsers, setLoadingGetUsers] = useState(false);
	const [loadingGetRecipes, setLoadingGetRecipes] = useState(false);

	const [stateUsers, setStateUsers] = useState(defaultState());
	const [stateRecipes, setStateRecipes] = useState(defaultState());
	const [stateUser, setStateUser] = useState(false);

	const [stateRecipe, setStateRecipe] = useState(DEFAULT_STATE_RECIPE);

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setLoadingGetUsers(true);
		const req = await api.users.get();
		if (req.status === 200) {
			var usersSelect = [{ label: t('Select a user'), value: '', selected: true }];
			req?.data?.map((val) => {
				usersSelect.push({
					label: val.name,
					value: val.user_name,
				});
			});
			setStateUsers({
				...req,
				data: usersSelect,
			});
		} else {
			msgs([req]);
		}
		setLoadingGetUsers(false);
	};

	const getRecipes = async (user_id) => {
		setLoadingGetRecipes(true);
		const req = await api.users.id.recipes.get({ userId: user_id });
		if (req.status === 200) {
			setStateRecipes({
				...stateRecipes,
				...req,
			});
		} else {
			msgs([req]);
		}
		setLoadingGetRecipes(false);
	};

	const insetRecipe = async () => {
		if (loadingInsertRecipe) {
			msg.warning(t('Please wait...'));
			return;
		}

		const fields = {
			user_name: stateRecipe.user_name,
		};

		if (invalidFields({ fields })) {
			msg.error(t('Please fill in all fields'));
			return;
		}

		if (stateRecipe.length < 1) {
			msg.error(t('Please enter at least one URL'));
			return;
		}

		var recipes = stateRecipe.recipes.map((item) => item.value);

		setLoadingInsertRecipe(true);

		const req = await api.recipes.post({
			user_name: stateRecipe.user_name,
			recipes: recipes,
		});

		if (req.status === 201) {
			msg.success(t('Recipe added successfully'));
			setStateRecipe(DEFAULT_STATE_RECIPE);
			// getRecipes();
		} else {
			msgs([req]);
		}
		setLoadingInsertRecipe(false);
	};

	const addField = () => {
		var fields = [...stateRecipe.recipes];
		fields.push({ label: '', value: '' });
		setStateRecipe({
			...stateRecipe,
			recipes: fields,
		});
	};

	const removeField = (keyItem) => {
		if (stateRecipe.recipes.length === 1) return;
		var fields = stateRecipe.recipes.filter((item, key) => key !== keyItem);
		setStateRecipe({
			...stateRecipe,
			recipes: fields,
		});
	};

	return (
		<div className={joinClass([c.container])}>
			<div className={joinClass(['base'])}>
				<div className={joinClass(['base-content'])}>
					<div className={joinClass([c.wrap])}>
						<div>
							<h3>{t('Add new recipes')}</h3>
							<div className={joinClass([c.form])}>
								<Select //
									_={{
										value: stateRecipe.user_name,
										onChange: (e) => {
											setStateRecipe({
												...stateRecipe,
												user_name: e.target.value,
											});
										},
									}}
									label={t('Select a user')}
									options={stateUsers?.data}
									loading={loadingGetUsers}
								/>
								<div className={joinClass([c['inputs-loop']])}>
									{stateRecipe.recipes.map((val, key) => {
										return (
											<div key={key} className={joinClass([c['input-box-cta']])}>
												<Input //
													label={t('URL')}
													_={{
														value: val.value,
														onChange: (e) => {
															var newState = [...stateRecipe.recipes];
															newState[key] = {
																...stateRecipe.recipes[key],
																value: e.target.value,
															};
															setStateRecipe({
																...stateRecipe,
																recipes: newState,
															});
														},
													}}
													className={joinClass([c.input])}
												/>
												<Btn
													_={{
														value: t('Remove'),
														onClick: () => removeField(key),
														...(stateRecipe.recipes.length === 1 ? { disabled: true } : {}),
													}}
												/>
											</div>
										);
									})}
								</div>

								<div className={joinClass([c['btn-wrap']])}>
									<Btn
										_={{
											value: t('Add more URL'),
											onClick: addField,
										}}
									/>
									<Btn
										_={{
											value: t('Save all recipes'),
											onClick: insetRecipe,
										}}
										loading={loadingInsertRecipe}
									/>
								</div>
							</div>
						</div>
						<div>
							<div className={joinClass([c.container])}>
								<div className={joinClass([c['title-box']])}>
									<h3>{t('All recipes')}</h3>
									<Select
										_={{
											value: stateUser,
											onChange: (e) => {
												setStateUser(e.target.value);
												if (e.target.value === '') {
													setStateRecipes(defaultState());
												} else {
													getRecipes(e.target.value);
												}
											},
										}}
										options={stateUsers?.data}
										loading={loadingGetUsers}
									/>
									<Btn
										_={{
											value: t('Reload'),
											onClick: () => getRecipes(stateUser),
										}}
										size="small"
										loading={loadingGetUsers}
									/>
								</div>
								{loadingGetRecipes ? (
									<Loading show />
								) : stateRecipes.status === 200 ? (
									<div>
										<Link to={`/user/${stateUser}`}>{t('View profile')}</Link>
										<div className={joinClass([c.loop])}>
											{stateRecipes.data.map((recipe, key) => {
												return (
													<div className={joinClass([c.item])} key={key}>
														<Img src={recipe.thumbnail} className={joinClass([c.img])} />
														<div className={joinClass([c['item-inner']])}>
															<div className={joinClass([c.title])}>{recipe.title}</div>
															<div className={joinClass([c['link-box']])}>
																<Link //
																	className={joinClass([c.link])}
																	to={`/user/${recipe.user_id}/recipe/${recipe.recipe_id}/shop`}
																	target="_blank"
																>
																	CookBook
																</Link>
																<Link //
																	className={joinClass([c.link])}
																	key={key}
																	to={recipe.url_you_tube}
																	target="_blank"
																>
																	YouTube
																</Link>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								) : (
									<div>{t('No recipes found')}</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
