import React, { useState } from 'react';

// Libs
import { Slide, ToastContainer } from 'react-toastify';

// Outros
import StoreContext from '@context/StoreContext';

// SCSS
import '@scss/global.scss';

// Screens
import { ScreensAdmin } from '@role/Screens/ScreensAdmin';
import { ScreensGeneral } from '@role/Screens/ScreensGeneral';

// Obj
import { isLogged } from '@func/auth';
import { STORE_SESSION_OBJ } from '@config/consts';

export const Routes = () => {
	var isLoggedIn = isLogged();

	if (!isLoggedIn) {
		isLoggedIn = STORE_SESSION_OBJ;
	}

	const [store, setStore] = useState(isLoggedIn);

	return (
		<StoreContext.Provider value={[store, setStore]}>
			{store?.token?.length ? <ScreensAdmin /> : <ScreensGeneral />}

			<ToastContainer
				position="bottom-right" //
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				transition={Slide}
			/>
		</StoreContext.Provider>
	);
};
