import React from 'react';

// CSS
import c from './RecipeCookItem.module.scss';

// Cpnt
import { joinClass } from '@func/cpnt';
import { Tag } from '@cpnt/Tag/Tag';

export const RecipeCookItem = (props) => {
	const {
		placeholder,
		className = '', //
		title,
		desc,
		tag
	} = props;

	return (
		<div className={joinClass([className, c.container])}>
			{placeholder ? <div className={joinClass([c.placeholder])} /> : null}
			<div className={joinClass([c.box])}>
				<div className={joinClass([c.title])}>{title}</div>
				{tag && <Tag text={tag} type="3" className={joinClass([c.tag])} />}
			</div>
			<div className={joinClass([c.desc])}>{desc}</div>
		</div>
	);
};
