import React, { Fragment, useContext } from 'react';

// Store
import StoreContext from '@context/StoreContext';
import { useTranslation } from 'react-i18next';

// CSS
import c from './Sidebar.module.scss';

// Func
import { hideSidebar, joinClass } from '@func/cpnt';

// Imgs
import imgIconX from '@icon/x-alt.svg';
import imgLogo from '@img/default/logo-cookbook.svg';

// Cpnt
import { Btn } from '@cpnt/Btns/Btn/Btn';
import { Icon } from '@cpnt/Icons/Icon/Icon';
import { IconVerified } from '@cpnt/Icons/IconVerified/IconVerified';
import { Img } from '@cpnt/Imgs/Img/Img';
import { TextIcon } from '@cpnt/TextIcon/TextIcon';
import { isLogged } from '@func/auth';
import { SIDEBAR_ADMIN } from '@role/Sidebar/SidebarAdmin';

export const Sidebar = (props) => {
	const [store, setStore] = useContext(StoreContext);
	const { t } = useTranslation();

	const {
		className = '', //
		sidebar,
		user = { data: {} },
		recipe = {},
	} = props;

	const { profile_img, name } = user.data;

	return (
		<>
			<div className={joinClass([c.sidebar, className, store?.config?.hideSidebar ? c['sidebar-hide'] : c['hidebar-show']])}>
				<div className={joinClass([c.inner])}>
					<div className={joinClass([c.top])}>
						<div className={joinClass([c['user-info']])}>
							<div className={joinClass([c.user])}>
								<Img src={profile_img} className={joinClass([c.img])} />
								<div className={joinClass([c.name])}>{name || t('Unable to load user')}</div>
								{name && <IconVerified />}
							</div>
							<Icon //
								img={imgIconX}
								color="var(--color-primary)"
								onClick={() => hideSidebar({ store, setStore })}
								classNameWrap={joinClass([c.x])}
								wrap
							/>
						</div>
						<div>
							{sidebar?.menu.map((val, key) => {
								return (
									<Fragment key={key}>
										<ul className={joinClass([c.list])}>
											{val.itens.map((val2, key2) => {
												var img = require(`@icon/${val2.icon}`);
												return (
													<li key={key2}>
														<TextIcon //
															className={joinClass([c['menu-item']])}
															text={val2.title}
															Icon={{ img: img, color: false, className: c.icon }}
															onClick={() => hideSidebar({ store, setStore })}
														/>
													</li>
												);
											})}
										</ul>
									</Fragment>
								);
							})}

							{
								/* prettier-ignore */
								isLogged() ? SIDEBAR_ADMIN.menu.map((val, key) => {
								return (
									<Fragment key={key}>
										{val.title && <h3 className={joinClass([c.title])}>{val.title}</h3>}
										<ul className={joinClass([c.list])}>
											{val.itens.map((val2, key2) => {
												var img = require(`@icon/${val2.icon}`);
												return (
													<li key={key2}>
														<TextIcon //
															className={joinClass([c['menu-item']])}
															text={val2.title}
															Icon={{ img: img, color: false, className: c.icon }}
															onClick={() => hideSidebar({ store, setStore })}
															link={val2.path}
														/>
													</li>
												);
											})}
										</ul>
									</Fragment>
								);
							}) : null
							}
						</div>
					</div>
					<div className={joinClass([c.bottom])}>
						<Btn
							_={{
								value: t('Create an Account'),
							}}
							type="white"
							wide
						/>
						<div className={joinClass([c.desc])}>{t('Discover endless culinary inspirations on Cookbook, where every recipe tells a story.')}</div>
						<div>
							<img src={imgLogo} alt="cook-book" className={joinClass([c.logo])} />
						</div>
					</div>
				</div>
			</div>
			<div className={joinClass([c.overlay, store?.config?.hideSidebar ? c['overlay-hide'] : ''])} onClick={() => hideSidebar({ store, setStore })}></div>
		</>
	);
};
