// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wS90X{position:relative;max-width:100%;overflow:hidden;border-radius:inherit;background-color:var(--color-primary-light-alt);z-index:10;width:100%;height:100%;border-radius:var(--border-radius);left:0;top:0}.wS90X:before{content:"";position:absolute;left:0;right:0;top:0;bottom:0;width:200%;height:200%;background:linear-gradient(to right, transparent, var(--color-primary-alpha-5), transparent);animation:iP19r 1s linear 0s;animation-iteration-count:infinite}@keyframes iP19r{from{left:-200%}to{left:200%}}.wS90X.pxYAP{border-radius:var(--border-radius)}.wS90X.MkmZV{border-radius:50%}.wS90X.S_0CQ{width:100%;height:var(--fs-title)}.wS90X.pZiVu{width:100%;height:var(--fs-text)}.wS90X.dcp7j{width:100%;height:40px}`, "",{"version":3,"sources":["webpack://./src/assets/scss/placeholders/_utils.scss","webpack://./src/components/Placeholders/Placeholder/Placeholder.module.scss"],"names":[],"mappings":"AAAA,OACC,iBAAA,CACA,cAAA,CACA,eAAA,CACA,qBAAA,CACA,+CAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CACA,kCAAA,CAEA,MAAA,CACA,KAAA,CAEA,cACC,UAAA,CACA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,4FAAA,CAEA,4BAAA,CACA,kCAAA,CAGD,iBACC,KACC,UAAA,CAED,GACC,SAAA,CAAA,CC1BF,aACC,kCAAA,CAGD,aACC,iBAAA,CAGD,aACC,UAAA,CACA,sBAAA,CAGD,aACC,UAAA,CACA,qBAAA,CAGD,aACC,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `wS90X`,
	"animationPlaceholderLoading": `iP19r`,
	"type-default": `pxYAP`,
	"type-circle": `MkmZV`,
	"type-title": `S_0CQ`,
	"type-desc": `pZiVu`,
	"type-box": `dcp7j`
};
export default ___CSS_LOADER_EXPORT___;
